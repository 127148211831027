import {createSlice} from "@reduxjs/toolkit";

const initialState = [ {
    id: "1234",
    projectsAmount: 12,
    client: "Kowalski Sp.zo.o",
    status: "Nowe",
    inNumber: "#321123",
    createAt: "22.02.22",
    isOffer: true
}]

const orderSlice =createSlice({
    name: "order",
    initialState,
    reducers: {

    }
});

export const orderActions = orderSlice.actions;

export default orderSlice.reducer;