export const findGateById = async (accessToken, id) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/gates/${id}`, {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${accessToken}`
        }
    });

    if (!response.ok) {
        throw new Error("Coś poszło nie tak")
    }

    const data = await response.json();

    return data
}