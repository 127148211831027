import React from 'react';
import {motion} from "framer-motion";
import {summaryBoxActions} from "../store/slice/summarySlice";
import {useDispatch} from "react-redux";
import useTranslations from "../hooks/useTranslations";

const SummaryBoxSteps = ({summaryBox, enableNextStep}) => {
    const steps = new Array(summaryBox.numberOfSteps).fill(null).map((a, index) => index + 1);
    const dispatch = useDispatch();
    const {summaryBoxStep} = useTranslations();

    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{
                default: {
                    duration: .4
                }
            }
            }
            className="summary-box__top">
            {steps.map((step,i) => {
                if (step === summaryBox.currentStep && steps.length !== summaryBox.currentStep) {
                    return <React.Fragment key={step+i}>
                        <p className={"active-step"}>{step + ` ${summaryBoxStep}`}</p>
                        <div className="underline"/>
                    </React.Fragment>
                } else if (step === summaryBox.currentStep && steps.length === summaryBox.currentStep) {
                    return (
                        <React.Fragment key={step + i}>
                            <div className="underline"/>
                            <p className={"active-step"}>{step + ` ${summaryBoxStep}`}</p>
                        </React.Fragment>
                    )
                } else {
                    return <p
                        style={step < summaryBox.currentStep || enableNextStep ? {cursor: "pointer"} : null}
                        onClick={() => {
                            if (step < summaryBox.currentStep || enableNextStep) {
                                dispatch(summaryBoxActions.updateCurrentStep(step))
                            }
                        }}>{step}</p>
                }
            })}
        </motion.div>
    );
};

export default SummaryBoxSteps;
