import React from 'react';
import './Login.scss'
import background from '../../../assets/bg2.png'
import logo from '../../../assets/l.png'
import LoginForm from "../../../layouts/LoginForm/LoginForm";
import Footer from "../../../layouts/Footer/Footer";
import {Redirect, Route, Switch} from "react-router-dom";
import PasswordForgotMessage from "../../../layouts/PasswordForgot/PasswordForgotMessage";
import PasswordReset from "../../../layouts/PasswordReset/PasswordReset";
import PasswordMessage from "../../../layouts/PasswordReset/PasswordMessage";
import PasswordForgoten from "../../../layouts/PasswordForgot/PasswordForgoten";
import Creator from "../../../layouts/Creator/Creator";


const Login = () => {

    return (
        <>
            <section className="section-login">
                <div className="bg-image">
                    <img src={background} alt="background image"/>
                </div>
                <div className="row">
                    <img className="login-logo" src={logo} alt="logo"/>
                </div>
                <div className="row">
                    <div className="login-container">
                        <div className="login-container__form">
                            <Switch>
                                <Route path={"/login"} exact>
                                    <LoginForm/>
                                </Route>
                                <Route path={"/login/password-forgot"} exact>
                                    <PasswordForgoten/>
                                </Route>
                                <Route path={"/login/password-forgot/success"} exact>
                                    <PasswordForgotMessage/>
                                </Route>
                                <Route path={"/login/password-reset/success"} exact>
                                    <PasswordMessage/>
                                </Route>
                                <Route path={"/login/password-reset/:token"}>
                                    <PasswordReset/>
                                </Route>
                                <Route path={"/mini-creator/step/:id"}>
                                    <Creator/>
                                </Route>
                                <Route path="*">
                                    <Redirect to={"/login"}/>
                                </Route>
                            </Switch>

                        </div>
                        <div className="login-container__img"></div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>

    );
};

export default Login;
