import {createSlice} from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: "user",
    initialState: {},
    reducers: {
        updateUser(state, action) {
            return state = {...state, ...action.payload}
        },
        signUp(state, action) {
            state = {...state, ...action.payload}
        },
    }
});

export const userActions = userSlice.actions;

export default userSlice.reducer;