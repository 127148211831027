import {createSlice} from "@reduxjs/toolkit";


const cartSlice = createSlice({
    name: "cart",
    initialState: {
        isOffer: true,
        cart: []
    },
    reducers: {
        addToCart(state, action) {
            state.cart = [...state.cart, action.payload]
        },
        isOfferToggle(state, action) {
            state.isOffer = action.payload
        }
    }
});

export const cartActions = cartSlice.actions;

export default cartSlice.reducer;