import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    accessories: [],
    chosenGate: {},
    client:'',
    componentItems: [],
    depth: undefined,
    gateAccessories: [],
    gateType: {},
    id: '',
    isOffer: false,
    isOrder: false,
    isEdit: false,
    ktmCode: '',
    ktmCodeDescription: '',
    leftArea: undefined,
    price: 0,
    rightArea: undefined,
    showDescription: false,
    showVisualization: false,
    width: undefined,
    height: undefined,
}

const gateCreatorSlice = createSlice({
    name: "gateCreator",
    initialState,
    reducers: {
        updateCreator(state, action) {
            return {
                ...state,
                ...action.payload,
            }
        },
        resetCreator(state) {
            return state = {
                ...initialState
            }

        },
        updateCreatorItems(state, action) {
            state.items = {
                ...state.items,
                ...action.payload
            }
        },
        updateItemDetailAccessories(state, action) {
            state.accessories = [...state.accessories, action.payload]
        },
        updateItemDetailQtyAccessories(state, action) {
            const accessoryToUpdateIndex = state.accessories.findIndex(a => a.id === action.payload.accessory)
            const accessoryToUpdate = state.accessories[accessoryToUpdateIndex];
            state.accessories[accessoryToUpdateIndex] = {
                ...accessoryToUpdate,
                quantity: action.payload.qty,
                totalPrice: accessoryToUpdate.price * action.payload.qty
            }
        },
        saveRender(state, action) {
            state.render = action.payload
        },
        deleteItemAccessories(state, action) {
            const id = action.payload;
            state.accessories = state.accessories.filter(accessory => accessory.id !== id)
        },
        updateItemDetailExtraAccessories(state, action) {
            state.gateAccessories = [...state.gateAccessories, action.payload]
        },
        updateQuantityExtraAccessories(state, action) {
            const findIndex = state.gateAccessories.findIndex(e => e.id === action.payload.accessory)
            state.gateAccessories[findIndex] = {
                ...state.gateAccessories[findIndex],
                qty: action.payload.qty
            }
        },
        updateItemDetailExtraAccessoriesPosition(state, action) {
            const findIndex = state.gateAccessories.findIndex(e => e.id === action.payload.id)
            state.gateAccessories[findIndex] = action.payload
        },
        deleteItemExtraAccessories(state, action) {
            const id = action.payload.accessory;
            state.accessories = state.accessories.filter(accessory => accessory.id !== id.id)
        },
        showVisualization(state) {
            state.showVisualization = !state.showVisualization
        },
        showDescription(state) {
            state.showDescription = true
        },
        hideDescription(state) {
            state.showDescription = false
        },
        updateKTM(state, action) {
            if (state.ktm.includes(action.payload)) {
                return;
            } else {
                state.ktm.push(action.payload)
            }
        },
        updateKTMDescription(state, action) {
            if (state.ktmDescription.includes(action.payload)) {
                return;
            } else {
                state.ktmDescription.push(action.payload)
            }
        }
    }
})

export const creatorActions = gateCreatorSlice.actions;

export default gateCreatorSlice.reducer;
