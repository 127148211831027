import {createSlice} from "@reduxjs/toolkit";


const contentSlice = createSlice({
    name: "content",
    initialState: {
        language: 'pl'
    },
    reducer: {
        changeLanguage(state, action) {
           return state.language = action.payload
        },
        updateContent(state, action) {
            return state = action.payload
        }
    }
})

export const contentActions = contentSlice.actions;

export default contentSlice.reducer;
