import moment from "moment";

export function sortByDate(
    dateA ,
    dateB,
    order = 'desc'
) {
    const first = moment(dateA).valueOf(),
        second = moment(dateB).valueOf();
    return order === 'asc' ? first - second : second - first;
}