import {createSlice} from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: "auth",
    initialState: {
        token: null,
        refreshToken: null,
        isLoggedIn: false
    },
    reducers: {
        updateAuth(state, action) {
            return state = {
                token: action.payload.token,
                refreshToken: action.payload.refresh_token,
                isLoggedIn: true
            }
        },
        logIn(state, action) {
            localStorage.setItem("isLoggedIn", true)
            localStorage.setItem("token", action.payload.token)
            localStorage.setItem("refreshToken", action.payload.refresh_token)

            return state = {
                token: action.payload.token,
                refreshToken: action.payload.refresh_token,
                isLoggedIn: true
            }
        },
        logOut(state) {
            localStorage.removeItem("isLoggedIn")
            localStorage.removeItem("token")
            localStorage.removeItem("refreshToken")
            state = {
                token: null,
                refreshToken: null,
                isLoggedIn: false
            }

            return state
        }
    }
});

export const authActions = authSlice.actions;

export default authSlice.reducer;