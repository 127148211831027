import React from 'react';
import logo from "../../assets/l.png";
import Creator from "../Creator/Creator";
import Footer from "../Footer/Footer";
import {NavLink} from "react-router-dom";
import {summaryBoxActions} from "../../store/slice/summarySlice";
import {useDispatch} from "react-redux";
import {creatorActions} from "../../store/slice/gateCreatorSlice";
import {visualizationActions} from "../../store/slice/visualizationSlice";

const MiniCreator = () => {
    const dispatch = useDispatch();

    return (
        <>
            <section className="section-login">
                <NavLink onClick={() => {
                    dispatch(summaryBoxActions.resetCurrentStep())
                    dispatch(creatorActions.resetCreator())
                    dispatch(summaryBoxActions.updateNumberOfSteps(4))
                    dispatch(visualizationActions.resetVisualization())
                }} to={'/mini-creator/step/0'} className="header-container">
                    <img src={logo} alt="logo"/>
                    <ul

                        className={`menu`}>
                        <li><NavLink to="/register">Załóż konto</NavLink>
                        </li>
                    </ul>
                </NavLink>
                <div className="dashboard">
                    <Creator/>
                </div>

            </section>
            <Footer/>
        </>
    );
};

export default MiniCreator;
