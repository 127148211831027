import React, {useEffect, useState} from 'react';
import {useMutation, useQueryClient} from "react-query";
import {authorizedPatchWithId} from "../../Creator/CreatorFunctions";
import {useDebouncedValue} from "@mantine/hooks";
import useTranslations from "../../../hooks/useTranslations";

const MontageCost = ({id, item}) => {
    const changeDiscountMutation = useMutation(authorizedPatchWithId)
    const [montageCost, setMontageCost] = useState(undefined);
    const [montagePrice] = useDebouncedValue(montageCost, 1000);
    const queryClient = useQueryClient()
    const {cartItemsMontageCostPlaceholder,
        cartItemsMontageCostTitle} = useTranslations();
    useEffect(() => {
        if (!!montagePrice?.toString()?.length && id) {
            changeDiscountMutation.mutate({
                url: `/api/cart_items/${id}/change_montage_cost`,
                data: {
                    montageCost: +montagePrice * 100,
                }
            }, {
                onSuccess: () => {
                    queryClient.invalidateQueries('cart')
                }
            })
        }
    }, [montagePrice])
    return (
        <div className="basket-item">
            <p className="basket-item__left">{cartItemsMontageCostPlaceholder}</p>

            <p className="basket-item__right"><input
                defaultValue={item?.montageCost / 100 || null}
                onChange={e => setMontageCost(+e.target.value)}
                className="content-accordion__input"
                type="number" placeholder={cartItemsMontageCostTitle}/></p>
        </div>
    );
};

export default MontageCost;
