import React from 'react';
import './Register.scss';
import background from '../../../assets/bg2.png'
import logo from '../../../assets/l.png'
import RegisterForm from "../../../layouts/RegisterForm/RegisterForm";
import Footer from "../../../layouts/Footer/Footer";
import {Link, Redirect, Route, Switch} from "react-router-dom";
import RegisterSuccess from "../../../layouts/RegisterSuccess/RegisterSuccess";

const Register = () => {
    return (
        <>
            <section className="section-login section-register">
                <div className="bg-image">
                    <img src={background} alt="background image"/>
                </div>
                <div className="row">
                    <Link to="/login">
                        <img className="login-logo" src={logo} alt="logo"/>
                    </Link>
                </div>
                <div className="row">
                    <div className="login-container">
                        <div className="login-container__form">
                            <Switch>
                                <Route path={"/register"} exact>
                                    <RegisterForm/>
                                </Route>
                                <Route path={"/register/success"} exact>
                                    <RegisterSuccess/>
                                </Route>
                                <Route path="*" >
                                    <Redirect to={"/register"}/>
                                </Route>
                            </Switch>
                        </div>
                        <div className="login-container__img"/>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
};

export default Register;