import React from 'react';
import RegisterIcon from '../../assets/icons/icon-register-top.png';
import './RegisterSuccess.scss';

const RegisterSuccess = () => {
    return (
        <section className="section-login-form section-reset-password section-reset-password-1">
            <form action="" className="form">
                <img src={RegisterIcon} alt="login form icon"/>
                    <h2>Rejestracja udana</h2>
                    <div className="form--inp" style={{maxWidth: "unset"}}>
                        <p>Kiedy tylko nasz administrator zaakceptuje Państwa konto, wysłana zostanie do Państwa
                            wiadomość email o aktywacji.</p>
                    </div>
            </form>
        </section>
    );
};

export default RegisterSuccess;