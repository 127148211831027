import React from 'react';
import '../../styles/global/_vars.scss';
import './Dashboard.scss';
import backgroundImage from '../../assets/bg3.png'

const AuthorizedBaseOverlay = (props) => {
    return (
        <section className="dashboard">
            <div className="bg-image">
                <img src={backgroundImage} alt="background image"/>
            </div>
            {props.children}
        </section>
    );
};

export default AuthorizedBaseOverlay;