import {createSlice} from "@reduxjs/toolkit";


const componentSlice = createSlice(
    {
        name: "components",
        initialState: [],
        reducers: {
            updateGateCategories(state, action) {
                return {...state, gateCategory: action.payload}
            }
        }
    }
)

export const componentsActions = componentSlice.actions;
export default componentSlice.reducer;