import React, {useState} from 'react';
import okIcon from '../../../assets/icons/ok-big.png';
import downloadIcon from '../../../assets/icons/download.png';
import listIcon from '../../../assets/icons/list.png';
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {uiActions} from "../../../store/slice/uiSlice";
import {Loader} from "@mantine/core";
import useTranslations from "../../../hooks/useTranslations";

const Summary = () => {
    const cart = useSelector(state => state.cart);
    const isOffer = cart?.isOffer;
    const disptach = useDispatch();
    const accessToken = localStorage.getItem("token");
    const creatorItem = useSelector(state =>  state.gateCreator);
    const [isLoading, setIsLoading] = useState(false)
    const [countPDF, setCountPDF] = useState(0)
    const isOfferHandler = () => {
        disptach(uiActions.isOfferToggle())
    }

    const getPDF = async () => {
        setIsLoading(true)
        setCountPDF(prevState => prevState + 1)
        try {
            let httpClient = new XMLHttpRequest();
            let pdfLink = `${process.env.REACT_APP_API_URL}/api/${isOffer ? "offers" : "orders"}/${creatorItem?.orderInfo?.id}/get_pdf`;
            httpClient.open('get', pdfLink, true);
            httpClient.setRequestHeader("Authorization", `Bearer ${accessToken}`)
            httpClient.responseType = "blob";
            httpClient.onload = function () {
                const file = new Blob([httpClient.response], {type: 'application/pdf'});
                const fileURL = URL.createObjectURL(file);
                const link = document.createElement("a");
                link.href = fileURL;
                link.download = `${isOffer ? creatorItem?.orderInfo?.offerNumber : creatorItem?.orderInfo?.orderNumber}.pdf`;
                link.click();
                // document.body.removeChild(link);
                URL.revokeObjectURL(fileURL);
                setIsLoading(false)
            };
            httpClient.send();

        } catch (e) {
            console.log(e);
        }

    }
    const {summaryOfferTitle,
        summaryOrderTitle,summaryDescription,summaryInfoAbout,summaryOffer,summaryOrder,summaryOfferNumber,
        summaryOrderNumber,summaryInternalOrderNumber,
        summaryInternalOfferNumber,summaryClient,summaryDownloadPDF,summaryDownloaded, summaryOfferList,
        summaryOrderList,} = useTranslations()
    return (
        <setcion className="summary-finished">
            <div className="container">
                <img className="section-top-icon" src={okIcon} alt="login icon"/>
                <h2 className="summary-finished-title">{cart.isOffer ? summaryOfferTitle : summaryOrderTitle}</h2>
                <p>{summaryDescription}</p>
                <div className="order-summary-container">
                    <h3>{summaryInfoAbout} {cart.isOffer ? summaryOffer : summaryOrder}</h3>
                    <div className="order-summary-item">
                        <div className="order-summary-item__left">{cart.isOffer ? summaryOfferNumber : summaryOrderNumber}</div>
                        <div className="order-summary-item__right">{creatorItem.isOffer || cart.isOffer? creatorItem?.orderInfo?.offerNumber : creatorItem?.orderInfo?.orderNumber}</div>
                    </div>
                    {creatorItem?.orderInfo?.clientNumber && <div className="order-summary-item">
                        <div className="order-summary-item__left">{cart.isOffer ? summaryInternalOfferNumber : summaryInternalOrderNumber}</div>
                        <div className="order-summary-item__right">{creatorItem?.orderInfo?.clientNumber}</div>
                    </div>}
                    <div className="order-summary-item">
                        <div className="order-summary-item__left">{summaryClient}</div>
                        <div className="order-summary-item__right">{creatorItem?.orderInfo?.client?.name} {creatorItem?.orderInfo?.client?.surname}</div>
                    </div>

                    <div className="summary-box__buttons">
                        <a
                            onClick={()=> {
                                if (!isLoading && countPDF < 1) {
                                    getPDF()
                                }
                            }
                        }
                            className="button-1" href="#">{!isLoading && countPDF >=1 ? summaryDownloaded : isLoading ? <Loader size={24}/>: <>{summaryDownloadPDF} <img src={downloadIcon}
                                                                              alt="background image"/></>}</a>
                        <Link
                            to={`/history/${cart.isOffer? "offers": "orders"}`}
                            onClick={isOfferHandler}
                            className="button-2">
                            {cart.isOffer ? summaryOfferList : summaryOrderList}
                            <img src={listIcon} alt="background image"/>
                        </Link>
                    </div>
                </div>
            </div>
        </setcion>
    );
};

export default Summary;
