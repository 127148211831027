import React, {useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import keyIcon from "../../assets/icons/password.png";
import '../LoginForm/LoginForm.scss';
import useInput from "../../hooks/useInput";

const PasswordForgoten = () => {
    const history = useHistory();
    const {
        value: enteredEmail,
        isTouched: enteredEmailIsTouched,
        hasError: enteredEmailHasError,
        enteredValueChangeHandler: enteredEmailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEnteredEmail
    } = useInput(enteredValue => enteredValue?.includes("@"));
    const [error, setError] = useState(false)

    const passwordResetHandler = (e) => {
        e.preventDefault();

        fetch(`${process.env.REACT_APP_API_URL}/api/reset_password/create_request`, {
            method: "POST",
            body: JSON.stringify({
                email: enteredEmail,
                redirectUrl: `${process.env.REACT_APP_HOMEPAGE_URL}login/password-reset`
            }),
            headers: {
                "Content-Type": "application/json"
            }
        }).then(response => {
            if (!response.ok) {
                setError(true)
            } else {
                history.push("/login/password-forgot/success")
            }
        })
    }

    return (
        <section className="section-login-form">
            <form onSubmit={passwordResetHandler} className="form">
                <h2>Resetowanie hasła</h2>
                <div className="form--inp">
                    <label htmlFor="email">Adres e-mail</label>
                    <input
                        onChange={enteredEmailChangeHandler}
                        type="email"
                        id="email"
                        placeholder="Wpisz adres e-mail"
                        required/>
                    {error && <p style={{color: "red", marginTop: 5, fontWeight:500, textAlign:"center"}}>Nie znaleziono uzytkownika!</p>}
                </div>
                <button
                    type="submit"
                    disabled={enteredEmailHasError || enteredEmail.trim().length < 3}
                    className="btn btn-primary">
                    Resetuj hasło
                    <img className="custom-icons" src={keyIcon} alt="login icon"/>
                </button>
                <div className="form__underline"></div>

                <p className="mb-0 body-m text-center"><Link to="/login">Wróć do logowania</Link></p>
            </form>
        </section>
    );
};

export default PasswordForgoten;
