import React, {useState} from 'react';
import {motion} from "framer-motion";
import eyeIcon from "../../assets/icons/eye.png";
import {useSelector} from "react-redux";
import {useQuery} from "react-query";
import {authorizedGet} from "../Creator/CreatorFunctions";
import OrderOfferCustomGateDetails from "./OrderOfferCustomGateDetails";

const OfferOrderList = () => {
    const cart = useSelector(state => state.cart);
    const [clickedItem, setClickedItem] = useState(null)
    const {data: offers} = useQuery("offers", () => authorizedGet("/api/offers"));
    const {data: orders} = useQuery("orders", () => authorizedGet("/api/orders"));

    return (
        <motion.section
            initial={{opacity: 0, y: 5}}
            animate={{opacity: 1, y: 0}}
            transition={{
                default: {
                    duration: .1
                }
            }
            }>
            <h2 className="dashboard-title">Lista złożonych {cart.isOffer ? "ofert" : "zamówień"} i zapytań</h2>
            <p>Bramy garażowe segmentowe znajdują zastosowanie w budownictwie jedno- i wielorodzinnym.</p>

            <div className="order-container">
                <div className="custom-table">
                    <table>
                        <thead>
                        <tr>
                            <th>Nr zamówienia</th>
                            <th>Ilość projektów</th>
                            <th>Klient</th>
                            <th>Status</th>
                            <th>Numer wewnętrzny zamówienia</th>
                            <th>Data realizacji</th>
                            <th>Akcje</th>
                        </tr>
                        </thead>
                        <tbody>
                        {orders?.length > 0 && orders.map((order, index) => {
                            return (
                                <>
                                    <tr style={{borderTop: "1px solid #CBD4E9"}}>
                                        <td>#345</td>
                                        <td>{order?.orderData?.items.length + order?.orderData?.accessories.length}</td>
                                        <td>{(order?.client.name + " " + order?.client.surname) || "-"}</td>
                                        <td>Nowe</td>
                                        <td>{order?.internalNumber || "-"}</td>
                                        <td>{new Date(order?.createdAt).toLocaleDateString() || "-"}</td>
                                        <td>
                                            <div className="table-icons"
                                                 style={{cursor: "pointer"}}
                                                 onClick={() => {
                                                     if (clickedItem === order.id) {
                                                         setClickedItem(null)
                                                     } else {
                                                         setClickedItem(order.id)
                                                     }
                                                 }}>
                                                <img className="accordion-icons__eye" src={eyeIcon}
                                                     alt="accordion icon"/>
                                            </div>
                                        </td>
                                    </tr>
                                    {clickedItem === order.id && <motion.div
                                        initial={{opacity: 0, y: 5}}
                                        animate={{opacity: 1, y: 0}}
                                        transition={{
                                            default: {
                                                duration: .1
                                            }
                                        }
                                        }>
                                        <div className="basket-container" style={{padding: 20, gap: 20}}>
                                            <div className="basket-left">
                                                {order?.orderData && order?.orderData?.items.map((gate, i) => {
                                                    return (
                                                        <div style={{
                                                            marginBottom: 50,
                                                            borderBottom: "1px solid #CBD4E9"
                                                        }}>
                                                            <OrderOfferCustomGateDetails gate={gate}/>
                                                        </div>

                                                    )
                                                })}
                                                {order?.orderData && order?.orderData?.accessories.map((acc, i) => {
                                                    return (
                                                        <div className="basket-box"
                                                             style={{
                                                                 marginBottom: 50,
                                                                 borderBottom: "1px solid #CBD4E9"
                                                             }}>
                                                            <div className="basket-item">
                                                                <p className="basket-item__left">Akcesorium</p>
                                                                <p className="basket-item__right">{acc?.name || "-"}</p>
                                                            </div>
                                                            <div className="basket-item">
                                                                <p className="basket-item__left">Ilość</p>
                                                                <p className="basket-item__right">{acc?.quantity || "-"}</p>
                                                            </div>
                                                        </div>

                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </motion.div>}
                                </>
                            )
                        })}
                        {offers?.length > 0 && offers.map(offer => {
                            return (
                                <tr>
                                    <td>#345</td>
                                    <td>{offer?.offerData?.items.length + offer?.offerData?.accessories.length}</td>
                                    <td>{(offer?.client.name + " " + offer?.client.surname) || "-"}</td>
                                    <td>Nowe</td>
                                    <td>{offer?.offerData?.internalNumber || "-"}</td>
                                    <td>{offer?.createdAt || "-"}</td>
                                    <td>
                                        <div className="table-icons">
                                            <img className="accordion-icons__eye" src={eyeIcon}
                                                 alt="accordion icon"/>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </motion.section>
    );
};

export default OfferOrderList;
