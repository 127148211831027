import React, {useMemo, useState} from 'react';
import styles from './Header.scss';
import logo from '../../assets/l.png';
import exitIcon from '../../assets/icons/exit.png';
import {Link, NavLink, useHistory} from "react-router-dom";
import {summaryBoxActions} from "../../store/slice/summarySlice";
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "../../store/slice/authSlice";
import {useQueryClient} from "react-query";
import {uiActions} from "../../store/slice/uiSlice";
import {Select} from "@mantine/core";


const Header = () => {
    const dispatch = useDispatch();
    const [openMenu, setOpenMenu] = useState(false)
    const queryClient = useQueryClient();
    const content = queryClient.getQueryData("contents");
    const language = useSelector(state => state.ui.language);

    const myAccount = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Header.MyAccount")?.content
        } else {
            return content?.find(e => e.slug === "Header.MyAccount")?.translations[language]?.name
        }
    }, [language, content])
    const myData = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Header.MyData")?.content
        } else {
            return content?.find(e => e.slug === "Header.MyData")?.translations[language]?.name
        }
    }, [language, content])
    const myEmail = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Header.MyEmail")?.content
        } else {
            return content?.find(e => e.slug === "Header.MyEmail")?.translations[language]?.name
        }
    }, [language, content])
    const myPassword = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Header.MyPassword")?.content
        } else {
            return content?.find(e => e.slug === "Header.MyPassword")?.translations[language]?.name
        }
    }, [language, content])
    const deactivate = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Header.Deactivate")?.content
        } else {
            return content?.find(e => e.slug === "Header.Deactivate")?.translations[language]?.name
        }
    }, [language, content])
    const logout = useMemo(() => {
        if (language === 'pl') {
            return content?.find(e => e.slug === "Header.Logout")?.content
        } else {
            return content?.find(e => e.slug === "Header.Logout")?.translations[language]?.name
        }
    }, [language, content])
    const handleLogout = () => {
        dispatch(authActions.logOut())

        window.location.reload();
    }
    return (
        <header>
            <div className="header-container">
                <NavLink to="/" onClick={() => dispatch(summaryBoxActions.resetCurrentStep())}>
                    <img className="login-logo" src={logo} alt="logo"/>
                </NavLink>
                <nav style={{
                    marginLeft: 'auto'
                }}>
                    <div id="burger-icon"
                         className={openMenu ? "open" : ""}
                         onClick={() => setOpenMenu(prev => !prev)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <ul
                        className={`menu ${openMenu ? "open" : ""}`}>
                        <li><NavLink to="/"
                                     onClick={() => dispatch(summaryBoxActions.resetCurrentStep())}>Dashboard</NavLink>
                        </li>
                        <li className="sub-menu">
                            <a href="#">{myAccount}</a>
                            <ul className="sub-menu-container">
                                <li><NavLink to="/user/data-edit/profile">{myData}</NavLink></li>
                                <li><NavLink to="/user/data-edit/email">{myEmail}</NavLink></li>
                                <li><NavLink to="/user/data-edit/password">{myPassword}</NavLink></li>
                                <li><NavLink to="/user/data-edit/deactivate">{deactivate}</NavLink></li>
                            </ul>
                        </li>
                        <li><a href={"#"} onClick={handleLogout}>{logout}</a><img className="login-logo" src={exitIcon}
                                                                                  alt="logo"/></li>
                    </ul>
                </nav>
                <div  className={'language-select'}>
                    <Select
                        value={language}
                        onChange={(value) => {
                            dispatch(uiActions.changeLanguage(value))
                        }}
                        style={{
                            maxWidth: 70,
                            marginLeft: 20
                        }}
                        data={[
                            {value: 'pl', label: 'PL'},
                            {value: 'en', label: 'EN'},
                            {value: 'ru', label: 'RU'},
                        ]}
                    />
                </div>

            </div>
        </header>
    );
};

export default Header;
