import React from 'react';
import {useSelector} from "react-redux";
import useTranslations from "../hooks/useTranslations";

const Ktm = ({creatorItem}) => {
    // TO DELETE WHEN READY
    // const ktm = useSelector(state => state.gateCreator.ktm);
    // const ktmDescription = useSelector(state => state.gateCreator.ktmDescription);
    const ktmAccessories = null;
    const {summaryBoxKtmDescription,summaryBoxKtmAccessoriesDescription,summaryBoxKtmAccessories} = useTranslations();
    return (
        <>
            <div className="summary-box__item">
                <p>KTM</p>
                <p>{creatorItem?.ktmCode}</p>
            </div>
            <div className="summary-box__item">
                <p>{summaryBoxKtmDescription}</p>
                <p>{creatorItem?.ktmCodeDescription}</p>
            </div>
            {ktmAccessories && <>
                <div className="summary-box__item">
                    <p>{summaryBoxKtmAccessories}</p>
                    <p>-</p>
                </div>
                <div className="summary-box__item">
                    <p>{summaryBoxKtmAccessoriesDescription}</p>
                    <p>-</p>
                </div>
            </>}
        </>
    );
};

export default Ktm;
