import React, {useEffect} from 'react';
import Header from "../../layouts/Header/Header";
import AuthorizedBaseOverlay from "./AuthorizedBaseOverlay";
import WelcomePage from "../../layouts/WelcomePage/WelcomePage";
import Footer from "../../layouts/Footer/Footer";
import {Link, Route, Switch} from "react-router-dom";
import NewClientAdd from "../../layouts/Clients/NewClientAdd/NewClientAdd";
import ClientList from "../../layouts/Clients/ClientList/ClientList";
import UserDataEdit from "../../layouts/User/UserDataEdit/UserDataEdit";
import Cart from "../../layouts/Cart/Cart";
import {useDispatch, useSelector} from "react-redux";
import History from "../../layouts/History/History";
import Creator from "../../layouts/Creator/Creator";
import Summary from "../../layouts/Cart/Summary/Summary";
import OfferOrderList from "../../layouts/OfferOrderList/OfferOrderList";
import jwt from "jwt-decode";
import {userActions} from "../../store/slice/userSlice";
import {authActions} from "../../store/slice/authSlice";
import ClientsHistory from "../../layouts/History/ClientsHistory";


const AuthorizedBase = ({isLoggedInCheck}) => {
    const dispatch = useDispatch();
    const token = localStorage.getItem("token")
    const refreshToken = localStorage.getItem("refreshToken")
    const user = useSelector(state => state.user)

    useEffect(() => {
        if (refreshToken) {
            fetch(`${process.env.REACT_APP_API_URL}/api/auth/refresh_token`, {
                method: "POST",
                body: JSON.stringify({
                    refresh_token: refreshToken,
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            }).then(response => {
                if (response.ok) {
                    return response.json()
                }
            })
                .then(data => {
                    const user = jwt(data?.token)
                    if (data?.token) {
                        dispatch(userActions.updateUser(user))
                    }
                    dispatch(authActions.logIn(data))
                })
        }
    }, [])

    return (
        <>
            <Header/>
            <AuthorizedBaseOverlay>
                <Switch>
                    <Route path="/policy">
                        <div className={"form"} style={{maxWidth: 1200, padding: 50, margin: "0 auto"}}>
                            <h2>Regulamin prywatnośći</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus animi aperiam commodi
                                consequuntur culpa debitis doloremque dolores doloribus eius eveniet ex excepturi
                                explicabo fuga
                                fugiat harum illo illum incidunt ipsa ipsam labore maxime nisi, nostrum omnis pariatur
                                quia
                                quibusdam quidem quis quo quos ratione reiciendis sequi sint tempore temporibus tenetur
                                unde
                                veritatis. Animi blanditiis deleniti eos excepturi in obcaecati pariatur possimus
                                reprehenderit
                                sint ullam. Esse, laboriosam, repudiandae! Amet asperiores consectetur consequatur culpa
                                debitis
                                distinctio eius fugiat illo incidunt, laborum minus nobis omnis provident, quaerat quas
                                quia
                                quibusdam quod sint sit suscipit ullam veniam veritatis, voluptas? Distinctio excepturi
                                ipsum
                                sed velit!</p>

                            <Link
                                to={"/"}
                                className="btn btn-primary"
                                style={{fontSize: 12, margin: "0 auto", marginTop: 20}}
                            >Strona główna
                            </Link>
                        </div>
                    </Route>
                    <Route path='/' exact>
                        <WelcomePage/>
                    </Route>
                    <Route path='/client/*'>
                        <NewClientAdd/>
                    </Route>
                    <Route path='/clients'>
                        <ClientList/>
                    </Route>
                    <Route path='/orders_clients'>
                        <ClientsHistory/>
                    </Route>
                    <Route path="/user/data-edit">
                        <UserDataEdit/>
                    </Route>
                    <Route path="/creator/step/:id">
                        <Creator/>
                    </Route>
                    {/*<Route path=":type/creator/step/:id/:gateID" exact>*/}
                    {/*    <Creator/>*/}
                    {/*</Route>*/}
                    <Route path={"/history/:type"} exact>
                        <History/>
                    </Route>
                    <Route path="/cart/summary" exact>
                        <Summary/>
                    </Route>
                    <Route path="/cart/:type" exact>
                        <Cart/>
                    </Route>

                    <Route path="/offer-order-list" exact>
                        <OfferOrderList/>
                    </Route>
                </Switch>
            </AuthorizedBaseOverlay>
            <Footer/>
        </>
    );
};

export default AuthorizedBase;
