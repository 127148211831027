import React, {useEffect, useMemo, useState} from 'react';
import "./SummaryBox.scss";
import useInput from "../hooks/useInput";
import './MiniCreatorSummary.scss';
import {Document, Page} from "react-pdf";
import pdf from '../../src/assets/pdf.pdf'
import { pdfjs } from 'react-pdf';
import {useMutation, useQueryClient} from "react-query";
import {unAuthorizedPost} from "../layouts/Creator/CreatorFunctions";
import {useSelector} from "react-redux";
import {Loader} from "@mantine/core";
import {useHistory} from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const MiniSummaryBox = () => {
        const creatorItem = useSelector(state => state.gateCreator);
        const accesoriesIds = useMemo(() => {
                if (creatorItem?.accessories) {
                        let ids = creatorItem.accessories.map(a => a.id).toString()
                        return '?accessories=' + ids
                } else {
                        return ''
                }
        },[creatorItem])
        const queryClient = useQueryClient();
        const content = queryClient.getQueryData("contents");
        const [showPolicy, setShowPolicy] = useState(false)
        const [width, setWidth]   = useState(window.innerWidth >= 1070 ? ((window.innerWidth - 40) * 50) / 100 : window.innerWidth - 40);
        const updateDimensions = () => {
                const widthValue =window.innerWidth >= 1070 ? ((window.innerWidth - 40) * 50) / 100 : window.innerWidth - 40
                setWidth(widthValue);
        }

        useEffect(() => {
                window.addEventListener("resize", updateDimensions);
                return () => window.removeEventListener("resize", updateDimensions);
        }, []);

        const {
                value: enteredEmail,
                isTouched: enteredEmailIsTouched,
                hasError: enteredEmailHasError,
                enteredValueChangeHandler: enteredEmailChangeHandler,
                inputBlurHandler: emailBlurHandler,
                reset: resetEnteredEmail
        } = useInput(enteredValue => enteredValue?.includes("@"));
        const {
                value: enteredMsg,
                isTouched: enteredMsgIsTouched,
                hasError: enteredMsgHasError,
                enteredValueChangeHandler: enteredMsgChangeHandler,
                inputBlurHandler: msgBlurHandler,
                reset: resetEnteredMsg
        } = useInput(enteredValue => enteredValue?.length > 1);
        const {
                value: enteredPhone,
                isTouched: enteredPhoneIsTouched,
                hasError: enteredPhoneHasError,
                enteredValueChangeHandler: enteredPhoneChangeHandler,
                inputBlurHandler: phoneBlurHandler,
                reset: resetEnteredPhone
        } = useInput(enteredValue => enteredValue?.length >= 8);

        const [numPages, setNumPages] = useState();
        const [file, setFile] = useState(undefined)
        const [agreement, setAgreement] = useState(false)
        function onDocumentLoadSuccess({ numPages }) {
                setNumPages(numPages);
        }
        const sendInquiry = useMutation(unAuthorizedPost)

        const getPDF = async () => {
                try {
                        let httpClient = new XMLHttpRequest();
                        let pdfLink = `${process.env.REACT_APP_API_URL}/api/gates/${creatorItem.id}/get_pdf${accesoriesIds}`;
                        httpClient.open('get', pdfLink, true);
                        httpClient.responseType = "blob";
                        httpClient.onload = function () {
                                const file = new Blob([httpClient.response], {type: 'application/pdf'});
                                setFile(file)
                        };
                        httpClient.send();
                } catch (e) {
                        console.log(e);
                }
        }

        useEffect(() => {
                window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                });

                if (creatorItem?.id) {
                        getPDF()
                }
        }, [])

        const onSubmit = e => {
                e.preventDefault()
                sendInquiry.mutate({
                        url: "/api/inquiries",
                        data: {
                                gate: creatorItem.id,
                                content: enteredMsg,
                                email: enteredEmail,
                                phone: enteredPhone
                        }
                })
        }

        return (
            <div className={'mini-creator-summary-container'} >
                    {!showPolicy ? <div className={'section-mini-creator-pdf-container'}>
                            {/*{!file && <p>PDF jest generowany...</p>}*/}
                            {/*{file && <iframe src={file} width={width} style={{*/}
                            {/*        background: '#ffffff',*/}
                            {/*        outline: 'none',*/}
                            {/*        border: 'none',*/}
                            {/*        height: 800*/}
                            {/*}}/>}*/}
                            <Document file={file} onLoadSuccess={onDocumentLoadSuccess} noData={<Loader/>} loading={<Loader/>}>
                                    {Array.apply(null, Array(numPages))
                                        .map((x, i)=>i+1)
                                        .map(page => <Page pageNumber={page} width={width}  scale={1} renderAnnotationLayer={false}
                                                           renderTextLayer={false}/>)}
                            </Document>
                    </div> :
                        <div className={"form"} style={{width: '100%', padding:20, justifyContent: 'flex-start'}}>
                            <h2>{content?.find(e =>e.slug === "PolicyTitle")?.content}</h2>
                            <p>{content?.find(e =>e.slug === "PolicyContent")?.content}</p>
        <button
            type={"button"}
            className="btn btn-primary"
            style={{width: "25%", fontSize: 12, marginTop: 20, marginRight: 'auto', textAlign: 'center', justifyContent: 'center'}}
            onClick={() => {
                    setShowPolicy(false)
            }
            }
        >Wróć do podglądu PDF
        </button>
</div>}
                    {!showPolicy && <section className="section-mini-creator-form">
                            <form className="form" onSubmit={onSubmit}>
                                    <h2 className={'title'}>Zestawienie</h2>
                                    <div className="form-container">
                                            <p className={'form--inp-title'}>Pobierz zestawienie</p>
                                            <div className="form--inp-container">
                                                    <p className={'form--inp-description'}>Pobierz zestawienie w formacie pdf na dysk lokalny.</p>

                                                    <button
                                                        onClick={(e) => {
                                                                e.preventDefault()
                                                                const fileURL = URL.createObjectURL(file);
                                                                const link = document.createElement("a");
                                                                link.href = fileURL;
                                                                link.download = `zapytanie.pdf`;
                                                                link.click();
                                                                // document.body.removeChild(link);
                                                                URL.revokeObjectURL(fileURL);
                                                        }}
                                                        className="btn btn-primary">
                                                            Pobierz
                                                    </button>
                                            </div>
                                    </div>
                                    <div className="form-container">
                                            <p className={'form--inp-title'}>Wyślij zapytanie</p>
                                            <div className="form--inp-container">
                                                    <div className="form--inp">
                                                            <label htmlFor="email">Treść zapytania</label>
                                                            <textarea value={enteredMsg}
                                                                      onChange={enteredMsgChangeHandler}
                                                                      onBlur={msgBlurHandler}
                                                                      id="message"
                                                                      placeholder="Wpisz treść wiadomości"
                                                                      required
                                                                      style={enteredMsgHasError ? {borderColor: "red"} : null}/>
                                                    </div>
                                                    <div className="form--inp">
                                                            <label htmlFor="email">Adres e-mail, na który wyślemy
                                                                    odpowiedź</label>
                                                            <input value={enteredEmail}
                                                                   onChange={enteredEmailChangeHandler}
                                                                   onBlur={emailBlurHandler}
                                                                   type="email"
                                                                   id="email"
                                                                   placeholder="Wpisz e-mail"
                                                                   required
                                                                   style={enteredEmailHasError ? {borderColor: "red"} : null}/>
                                                    </div>
                                                    <div className="form--inp">
                                                            <label htmlFor="email">Numer telefonu</label>
                                                            <input value={enteredPhone}
                                                                   onChange={enteredPhoneChangeHandler}
                                                                   onBlur={phoneBlurHandler}
                                                                   id="phoneNUmber"
                                                                   placeholder="Wpisz numer telefonu"
                                                                   required
                                                                   style={enteredPhoneHasError ? {borderColor: "red"} : null}/>
                                                    </div>
                                                    <div className="checkbox-container">
                                                            <label className="cb-box">
                                                                    Wyrażam zgodę na przetwarzanie moich danych osobowych podanych w formularzu w celu udzielenia odpowiedzi na zadanie pytanie.
                                                                    <input
                                                                        onChange={e => setAgreement(prev => !prev)}
                                                                        id="agreement1"
                                                                        type="checkbox"/>
                                                                    <span className="checkmark"/>
                                                            </label>
                                                    </div>
                                                    <p style={{marginBottom: 40}}>
                                                            Podanie danych w formularzu kontaktowym jest dobrowolne,
                                                            jednakże ich niepodanie uniemożliwi Panu/Pani przesłanie do
                                                            nas wiadomości.
                                                            Administratorem danych osobowych jest "Stalprodukt – Zamość"
                                                            Sp. z o.o., ul. Kilińskiego 86, 22-400 Zamość.
                                                            Szczegółowe informacje dostępne są w <span
                                                        onClick={() => setShowPolicy(true)}
                                                        style={{color: "blue", textDecoration: "underline", cursor: 'pointer'}}
                                                    >Polityce prywatności.</span>
                                                    </p>
                                                    <button
                                                        type={"submit"}
                                                        disabled={!agreement}
                                                        className="btn btn-primary">
                                                            {!sendInquiry.isLoading ? 'Wyślij' : <Loader color={"white"} size={20}/>}
                                                    </button>
                                                    {sendInquiry.isSuccess && <p style={{color: "green"}}>Zapytanie zostało wysłane!</p>}
                                                    {sendInquiry.isError && <p style={{color: "red"}}>Wystąpił bład, spróbuj ponowanie później.</p>}
                                            </div>
                                    </div>
                            </form>
                    </section>}
            </div>
        );
    }
;

export default MiniSummaryBox;
