import {useLocation} from "react-router-dom";

export const authorizedGet = async (url) => {
    const accessToken = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    const isMiniCreator = window.location.pathname.startsWith('/mini-creator');

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        });

        if (response.status === 401 && !isMiniCreator) {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/refresh_token`, {
                method: "POST",
                body: JSON.stringify({
                    refresh_token: refreshToken
                }),
                headers: {
                    "Content-Type": "application/json",
                }
            });

            if (response.status === 401) {
                localStorage.removeItem("isLoggedIn")
                localStorage.removeItem("token")
                localStorage.removeItem("refreshToken")
            }

            const data = await response.json()
            if (data) {
                localStorage.removeItem("token")
                localStorage.removeItem("refreshToken")
                localStorage.setItem("token", data.token)
                localStorage.setItem("refreshToken", data.refresh_token)
            }
        }
        if (!response.ok) {
            throw new Error("Coś poszło nie tak")
        }

        return await response.json()

    } catch (e) {
        console.error(e)
    }
};

export const authorizedGetById = async (url, id = "") => {
    const accessToken = localStorage.getItem("token");
    try {
        if (id) {
            const response = await fetch(`${process.env.REACT_APP_API_URL}${url}/${id}`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            if (!response.ok) {
                throw new Error("Coś poszło nie tak klient")
            }

            return await response.json()
        } else {
            throw new Error("Coś poszło nie tak klient")
        }

    } catch (e) {
        console.error(e)
    }
};

export const createComponentItemImg = async ({data}) => {
    const accessToken = localStorage.getItem("token");
    if (data) {
        const formdata = new FormData()
        formdata.append('file', data)

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/attachments`, {
                method: "POST",
                body: formdata,
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            })
            if (!response.ok) {
                return;
            }

            return response.json()

        } catch (e) {
            console.log(e)
        }

    }
}

export const unAuthorizedPost = async ({url, data}) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        });

        if (!response.ok) {
            throw new Error("Coś poszło nie tak")
        }
        return await response.json()
    }
;

export const authorizedPost = async ({url, data}) => {
        const accessToken = localStorage.getItem("token");

        const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            }
        });

        if (!response.ok) {
            throw new Error("Coś poszło nie tak")
        }
        return await response.json()
    }
;

export const authorizedPatchWithId = async ({url, data}) => {
    const accessToken = localStorage.getItem("token");
    try {
        const response = await fetch(`${process.env.REACT_APP_API_UPLOADS}${url}`, {
            method: "PATCH",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/merge-patch+json",
                "Authorization": `Bearer ${accessToken}`
            }
        });
        if (!response.ok) {
            throw new Error("Coś poszło nie tak")
        }

        return await response.json()

    } catch (e) {
        throw new Error("Coś poszło nie tak")
    }
};

export const authorizedArrayPatchWithId = async ({url, items}) => {
    const accessToken = localStorage.getItem("token");
    let responses = [];
    for (const i of items) {
        let result = await fetch(`${process.env.REACT_APP_API_UPLOADS}${url}`, {
                        method: "PATCH",
                        body: JSON.stringify({
                            gate_accessory: i?.data?.id,
                            quantity: 1
                        }),
                        headers: {
                            "Content-Type": "application/merge-patch+json",
                            "Authorization": `Bearer ${accessToken}`
                        }
                    }).then(r => r.json())
        responses.push(result)
    }

    return responses
};

export const authorizedArrayAccPatchWithId = async ({url, items}) => {
    const accessToken = localStorage.getItem("token");
    let responses = [];
    for (const i of items) {
        let result = await fetch(`${process.env.REACT_APP_API_UPLOADS}${url}`, {
            method: "PATCH",
            body: JSON.stringify({
                gate_accessory: i?.data?.id,
                quantity: 1
            }),
            headers: {
                "Content-Type": "application/merge-patch+json",
                "Authorization": `Bearer ${accessToken}`
            }
        }).then(r => r.json())
        responses.push(result)
    }

    return responses
};

export const authorizedPatch = async ({url, data}) => {
    const accessToken = localStorage.getItem("token");
    const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/merge-patch+json",
            "Authorization": `Bearer ${accessToken}`
        }
    });
    if (!response.ok) {
        throw new Error("Coś poszło nie tak")
    }

    return await response.json()

};

export const authorizedPutWithId = async ({url, data, id}) => {
    const accessToken = localStorage.getItem("token");

    const response = await fetch(`${process.env.REACT_APP_API_URL}${url}/${id}`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    });

    if (!response.ok) {
        throw new Error("Coś poszło nie tak")
    }

    return await response.json()
}
export const authorizedPut = async ({url,data}) => {
    const accessToken = localStorage.getItem("token");

    const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    });

    if (!response.ok) {
        throw new Error("Coś poszło nie tak")
    }

    return await response.json()
}

export const authorizedDeleteWithId = async ({url, id}) => {
    const accessToken = localStorage.getItem("token");
    const response = await fetch(`${process.env.REACT_APP_API_URL}${url}/${id}`, {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${accessToken}`
        }
    });

    if (!response.ok) {
        throw new Error("Coś poszło nie tak")
    }
    return response;
}

