import React from 'react';
import {NavLink} from "react-router-dom";
import {summaryBoxActions} from "../../store/slice/summarySlice";
import {creatorActions} from "../../store/slice/gateCreatorSlice";
import {visualizationActions} from "../../store/slice/visualizationSlice";
import logo from "../../assets/l.png";
import Footer from "../Footer/Footer";
import {useDispatch, useSelector} from "react-redux";
import MiniSummaryBox from "../../components/MiniSummaryBox";
import {authorizedGet} from "../Creator/CreatorFunctions";


const MiniCreatorSummary = () => {
    const dispatch = useDispatch();

    return (
        <>
            <section className="section-login">
                <NavLink onClick={() => {
                    dispatch(summaryBoxActions.resetCurrentStep())
                    dispatch(creatorActions.resetCreator())
                    dispatch(summaryBoxActions.updateNumberOfSteps(4))
                    dispatch(visualizationActions.resetVisualization())
                }} to={'/mini-creator/step/0'} className="header-container">
                    <img src={logo} alt="logo"/>
                    <ul

                        className={`menu`}>
                        <li><NavLink to="/register">Załóż konto</NavLink>
                        </li>
                    </ul>
                </NavLink>
                <div className="dashboard-content__right">
                    <div className="summary-container summary-container--mini">
                        <MiniSummaryBox/>
                    </div>
                </div>

            </section>
            <Footer/>
        </>
    );
};

export default MiniCreatorSummary;
