import React, {useEffect, useState} from 'react';
import enterIcon from "../../assets/icons/exit.png";
import {Link, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {clientSliceAction} from "../../store/slice/clientSlice";
import useInput from "../../hooks/useInput";
import {useQueryClient} from "react-query";

const RegisterForm = () => {
    const queryClient = useQueryClient();
    const content = queryClient.getQueryData("contents");
    const history = useHistory();
    const [error, setError] = useState(undefined)
    const [showStatute, setShowStatute] = useState(false)
    const [showPolicy, setShowPolicy] = useState(false)
    const clients = useSelector(state => state.clients);
    const [agreements, setAgreements] = useState({
        agreement1: false,
        agreement2: false
    })
    const {
        value: enteredCompany,
        isTouched: enteredCompanyIsTouched,
        hasError: enteredCompanyHasError,
        enteredValueChangeHandler: enteredCompanyChangeHandler,
        inputBlurHandler: companyBlurHandler,
        reset: resetEnteredCompany
    } = useInput(() => true);

    const {
        value: enteredNip,
        isTouched: enteredNipIsTouched,
        hasError: enteredNipHasError,
        enteredValueChangeHandler: enteredNipChangeHandler,
        inputBlurHandler: nipBlurHandler,
        reset: resetEnteredNip
    } = useInput((enteredValue) => enteredValue.trim().length <= 10);

    const {
        value: enteredName,
        isTouched: enteredNameIsTouched,
        hasError: enteredNameHasError,
        enteredValueChangeHandler: enteredNameChangeHandler,
        inputBlurHandler: nameBlurHandler,
        reset: resetEnteredName
    } = useInput(enteredValue => enteredValue.length > 0);

    const {
        value: enteredSurname,
        isTouched: enteredSurnameIsTouched,
        hasError: enteredSurnameHasError,
        enteredValueChangeHandler: enteredSurnameChangeHandler,
        inputBlurHandler: surnameBlurHandler,
        reset: resetEnteredSurname
    } = useInput((enteredValue) => enteredValue.trim().length > 0);

    const {
        value: enteredCompanyShort,
        isTouched: enteredCompanyShortIsTouched,
        hasError: enteredCompanyShortHasError,
        enteredValueChangeHandler: enteredCompanyShortChangeHandler,
        inputBlurHandler: companyShortBlurHandler,
        reset: resetEnteredCompanyShort
    } = useInput(() => true);

    const {
        value: enteredEmail,
        isTouched: enteredEmailIsTouched,
        hasError: enteredEmailHasError,
        enteredValueChangeHandler: enteredEmailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEnteredEmail
    } = useInput((enteredValue) => enteredValue?.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ));

    const {
        value: enteredPhone,
        isTouched: enteredPhoneIsTouched,
        hasError: enteredPhoneHasError,
        enteredValueChangeHandler: enteredPhoneChangeHandler,
        inputBlurHandler: phoneBlurHandler,
        reset: resetEnteredPhone
    } = useInput(() => true);

    const {
        value: enteredPin,
        isTouched: enteredPinIsTouched,
        hasError: enteredPinHasError,
        enteredValueChangeHandler: enteredPinChangeHandler,
        inputBlurHandler: pinBlurHandler,
        reset: resetEnteredPin
    } = useInput(() => true);

    const {
        value: enteredCity,
        isTouched: enteredCityIsTouched,
        hasError: enteredCityHasError,
        enteredValueChangeHandler: enteredCityChangeHandler,
        inputBlurHandler: cityBlurHandler,
        reset: resetEnteredCity
    } = useInput(() => true);

    const {
        value: enteredStreet,
        isTouched: enteredStreetIsTouched,
        hasError: enteredStreetHasError,
        enteredValueChangeHandler: enteredStreetChangeHandler,
        inputBlurHandler: streetBlurHandler,
        reset: resetEnteredStreet
    } = useInput(() => true);

    const {
        value: enteredZipCode,
        isTouched: enteredZipCodeIsTouched,
        hasError: enteredZipCodeHasError,
        enteredValueChangeHandler: enteredZipCodeChangeHandler,
        inputBlurHandler: zipCodeBlurHandler,
        reset: resetEnteredZipCode
    } = useInput(() => true);

    const isFormValid = (enteredNameIsTouched && !enteredNameHasError) && (enteredSurnameIsTouched && !enteredSurnameHasError) && (enteredEmailIsTouched && !enteredEmailHasError) && (agreements.agreement1 && agreements.agreement2);

    const registerNewClient = async (client) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients/register`, {
            method: "POST",
            body: JSON.stringify(client),
            headers: {
                'Content-Type': 'application/json'
            }
        })

        if (!response.ok) {
            const r = await response.json()
            if (r.violations && r.violations[0]?.code === "23bd9dbf-6b9b-41cd-a99e-4844bcf3077f") {
                setError("Użytkownik o podanym adresie email już istnieje w bazie!")
            } else if (r.detail === "Pin number not found") {
                setError("Podany numer PIN nie został znaleziony w bazie")
            } else if (r.detail === "email: This email is already used.") {
                setError("Użytkownik o podanym adresie email już istnieje w bazie!")
            } else {
                setError("Coś poszło nie tak, spróbuj ponownie...")
            }
        }

        const responseData = await response.json();
        if (responseData) {
            setError(undefined)
            history.push("register/success")
        }
    }

    const registerClientHandler = (e) => {
        e.preventDefault();
        registerNewClient({
            company: enteredCompany,
            companyShort: enteredCompanyShort,
            phone: enteredPhone,
            vatNumber: enteredNip,
            pinNumber: enteredPin,
            email: enteredEmail,
            name: enteredName,
            surname: enteredSurname,
            city: enteredCity,
            street: enteredStreet,
            zipCode: enteredZipCode
        })

    }

    return (
        <div>
            <section className="section-login-form section-register">
                {(!showStatute && !showPolicy) && <form onSubmit={registerClientHandler} className="form">
                    <h2>Załóż konto</h2>
                    <div>
                        <div className="form--inp">
                            <label htmlFor="company">Nazwa firmy</label>
                            <input onChange={enteredCompanyChangeHandler}
                                   onBlur={companyBlurHandler}
                                   value={enteredCompany}
                                   type="text"
                                   id="company"
                                   placeholder="Nazwa firmy"
                            />
                        </div>
                        <div className="form--inp">
                            <label htmlFor="company_short">Nazwa skrócona firmy</label>
                            <input
                                onChange={enteredCompanyShortChangeHandler}
                                onBlur={companyShortBlurHandler}
                                value={enteredCompanyShort}
                                type="text"
                                id="company_short"
                                placeholder="Nazwa skórcona firmy"
                            />
                        </div>
                    </div>
                    <div>
                        <div className="form--inp">
                            <label htmlFor="name">Imię*</label>
                            <input
                                onChange={enteredNameChangeHandler}
                                onBlur={nameBlurHandler}
                                value={enteredName}
                                type="text"
                                id="name"
                                placeholder="np. Jan"
                                required/>
                        </div>
                        <div className="form--inp">
                            <label htmlFor="surname">Nazwisko*</label>
                            <input
                                onChange={enteredSurnameChangeHandler}
                                onBlur={surnameBlurHandler}
                                value={enteredSurname}
                                type="text"
                                id="surname"
                                placeholder="np. Kowalski"
                                required/>
                        </div>
                    </div>
                    <div>
                        <div className="form--inp">
                            <label htmlFor="email">Adres email*</label>
                            <input
                                onChange={enteredEmailChangeHandler}
                                onBlur={emailBlurHandler}
                                value={enteredEmail}
                                type="email"
                                id="email"
                                placeholder="Email"
                                required/>
                        </div>
                        <div className="form--inp">
                            <label htmlFor="phone">Numer telefonu</label>
                            <input
                                onChange={enteredPhoneChangeHandler}
                                onBlur={phoneBlurHandler}
                                value={enteredPhone}
                                placeholder="Wporwadź nr. telefonu"
                                type="text"
                                id="phone"
                            />
                        </div>
                    </div>
                    <div>
                        <div className="form--inp">
                            <label htmlFor="name">Ulica</label>
                            <input
                                onChange={enteredStreetChangeHandler}
                                onBlur={streetBlurHandler}
                                value={enteredStreet}
                                type="text"
                                id="street"
                                placeholder="np. ul. Miła"
                            />
                        </div>
                        <div className="form--inp">
                            <label htmlFor="surname">Miasto</label>
                            <input
                                onChange={enteredCityChangeHandler}
                                onBlur={cityBlurHandler}
                                value={enteredCity}
                                type="text"
                                id="city"
                                placeholder="np. Warszawa"
                            />
                        </div>
                    </div>
                    <div>
                        <div className="form--inp">
                            <label htmlFor="surname">Kod pocztowy</label>
                            <input
                                onChange={enteredZipCodeChangeHandler}
                                onBlur={zipCodeBlurHandler}
                                value={enteredZipCode}
                                type="text"
                                id="city"
                                placeholder="np. 00-100"
                            />
                        </div>
                        <div className="form--inp">
                            <label htmlFor="country">Państwo</label>
                            <input type="text" id="country" placeholder="Wpisz państwo..."/>
                        </div>
                        {/*<div className="form--inp">*/}
                        {/*    <label htmlFor="language">Język</label>*/}
                        {/*    <select id="language">*/}
                        {/*        <option value="pl">Polski</option>*/}
                        {/*        <option value="en">Angielski</option>*/}
                        {/*    </select>*/}
                        {/*</div>*/}
                    </div>
                    <div>
                        <div className="form--inp">
                            <label htmlFor="vatNumber">NIP firmy</label>
                            <input
                                onChange={enteredNipChangeHandler}
                                onBlur={nipBlurHandler}
                                value={enteredNip}
                                type="text"
                                maxLength={10}
                                minLength={10}
                                id="vatNumber"
                                placeholder="10-cyfrowy NIP firmy"/>
                        </div>
                        <div className="form--inp">
                            <label htmlFor="pin">4 cyfrowy PIN partnera</label>
                            <input
                                onChange={enteredPinChangeHandler}
                                onBlur={pinBlurHandler}
                                value={enteredPin}
                                maxLength={4}
                                type="text"
                                id="pin"
                                placeholder="Jeśli posiadasz PIN, wpisz go tutaj"/>
                        </div>
                    </div>

                    <button
                        type={"submit"}
                        className="btn btn-primary"
                        disabled={!isFormValid}>
                        Zakładam konto
                        <img
                            className="custom-icons"
                            src={enterIcon}
                            alt="login icon"/>
                    </button>
                    {error && <p style={{color: "red", marginBottom: 20, fontSize: 12, fontWeight: "bold"}}>{error}</p>}
                    <div className="checkbox-container">
                        <label className="cb-box">
                            Zapoznałem się i akcptuje treść <span
                            onClick={() => setShowStatute(true)}
                            style={{color: "blue", textDecoration: "underline"}}
                        >regulaminu</span>
                            <input
                                onChange={e => setAgreements(prev => {
                                    return {...prev, agreement1: e.target.checked}
                                })}
                                id="agreement1"
                                type="checkbox"/>
                            <span className="checkmark"/>
                        </label>
                        <label className="cb-box">
                            Zapoznałem się <span
                            onClick={() => setShowPolicy(true)}
                            style={{color: "blue", textDecoration: "underline"}}
                        >z klauzulą informacyjną</span> dotyczącą przetarzania dancyh
                            osobowych
                            <input
                                onChange={e => setAgreements(prev => {
                                    return {...prev, agreement2: e.target.checked}
                                })}
                                type="checkbox"
                                id="agreement2"
                            />
                            <span className="checkmark"/>
                        </label>
                    </div>

                    <div className="form__underline"/>

                    <p className="mb-0 body-m text-center"><Link to="/login">Wróć do logowania</Link></p>
                </form>}
                {showStatute && <div className={"form"}>
                    <h2>{content?.find(e =>e.slug === "StatuteTitle")?.content}</h2>
                    <p>{content?.find(e =>e.slug === "StatuteContent")?.content}</p>
                    <button
                        type={"button"}
                        className="btn btn-primary"
                        style={{width: "50%", fontSize: 12}}
                        onClick={() => {
                            setShowPolicy(false)
                            setShowStatute(false)
                        }
                        }
                    >Wróć do rejestracji
                    </button>
                </div>}
                {showPolicy && <div className={"form"}>
                    <h2>{content?.find(e =>e.slug === "PolicyTitle")?.content}</h2>
                    <p>{content?.find(e =>e.slug === "PolicyContent")?.content}</p>
                    <button
                        type={"button"}
                        className="btn btn-primary"
                        style={{width: "50%", fontSize: 12}}
                        onClick={() => {
                            setShowPolicy(false)
                            setShowStatute(false)
                        }
                        }
                    >Wróć do rejestracji
                    </button>
                </div>}
            </section>
        </div>
    );
};

export default RegisterForm;