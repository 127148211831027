import React from 'react';
import {Link} from "react-router-dom";
import logoIcon from "../../assets/icons/login-icon-top-2.png";

const PasswordMessage = () => {
    return (
        <section className="section-login-form section-reset-password section-reset-password-1">
            <form action="" className="form">
                <img src={logoIcon} alt="login form icon"/>
                    <h2>Resetowanie hasła</h2>
                    <div className="form--inp">
                        <p>Twoje hasło zostało zmienione. Od dziś możesz logować się używając nowego hasła</p>
                    </div>

                    <div className="login-form__underline"></div>

                    <p className="mb-0 body-m text-center">
                        <Link to="/login">Zaloguj się przy użyciu nowego hasła</Link>
                    </p>
            </form>
        </section>
    );
};

export default PasswordMessage;