import './App.scss';
import Login from "../../pages/Auth/Login/Login";
import {Redirect, Route, Switch} from 'react-router-dom';
import Register from "../../pages/Auth/Register/Register";
import AuthorizedBase from "../../pages/AuthorizedBase/AuthorizedBase";
import {useSelector} from "react-redux";
import {useQuery} from "react-query";
import {ReactQueryDevtools} from 'react-query/devtools'
import React from "react";
import MiniCreator from "../MiniCreator/MiniCreator";
import MiniCreatorSummary from "../MiniCreator/MiniCreatorSummary";

function App() {
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const localIsLoggedIn = localStorage.getItem("isLoggedIn") !== null;

    const contentGet = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/contents?pagination=false`);

            if (!response.ok) {
                throw new Error("Coś poszło nie tak")
            }

            return await response.json()

        } catch (e) {
            console.error(e)
        }
    };

    const {data: content, isLoading} = useQuery("contents", contentGet, {
        keepPreviousData: true,
    })


    return (
        <>
            <div>
                {(isLoggedIn || localIsLoggedIn) &&
                    <>
                        <Route path="/">
                            <AuthorizedBase/>
                        </Route>
                        <Route path="*">
                            <Redirect to="/"/>
                        </Route>
                    </>
                }

                {(!isLoggedIn && !localIsLoggedIn) &&
                    <Switch>
                        <Route exact path="/login">
                            <Login/>
                        </Route>
                        <Route path="/login/*">
                            <Login/>
                        </Route>
                        <Route path="/register">
                            <Register/>
                        </Route>
                        <Route path={"/mini-creator/summary"}>
                            <MiniCreatorSummary/>
                        </Route>
                        <Route path="/mini-creator">
                            <MiniCreator/>
                        </Route>
                        <Route path="*">
                            <Redirect to={"/login"}/>
                        </Route>
                    </Switch>}
                {/*<Route path={"/*"}>*/}
                {/*    <div style={{height: "100vh"}}>*/}
                {/*        <Header/>*/}
                {/*        <DashboardOverlay>*/}
                {/*            <div style={{height: "100%", minHeight: 300, display: "flex", justifyContent: "center", alignItems: "center"}}>*/}
                {/*                <p style={{fontSize:40, fontWeight: "bold", textAlign: "center"}}>404<br/> Strona nie odnaleziona</p>*/}
                {/*            </div>*/}
                {/*        </DashboardOverlay>*/}
                {/*       <Footer/>*/}
                {/*    </div>*/}
                {/*</Route>*/}
            </div>
            <ReactQueryDevtools/>
        </>
    );
}

export default App;
