import React, {useEffect, useState} from 'react';
import expandIcon from "../../../assets/icons/expand.png";
import {motion} from "framer-motion";
import {useHistory, useParams} from "react-router-dom";
import {creatorActions} from "../../../store/slice/gateCreatorSlice";
import {useDispatch, useSelector} from "react-redux";
import {useMutation, useQuery} from "react-query";
import {
    authorizedGetById,
    authorizedPatchWithId,
    authorizedPost,
    authorizedPutWithId
} from "../../Creator/CreatorFunctions";
import {Checkbox, Select} from "@mantine/core";
import useTranslations from "../../../hooks/useTranslations";

const CartPreview = ({
                         receiverFromSelect,
                         updateCartPrice,
                         setPriceData,
                         setChosenAcc,
                         chosenAcc,
                         setChosenItems,
                         chosenItems,
                         setInternalNumber,
                         setReceiver,
                         receiver,
                         setReceiverFromSelect,
                         setValidUntil,
                         setOrderNotes,
                         cart,
                         clickedItem,
                         setClickedItem
                     }) => {
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const accessToken = localStorage.getItem("token");
    const params = useParams();
    const isOrder = params?.type === 'carts';
    const creatorItem = useSelector(state => state.gateCreator);
    const [isOneTimeOffer, setIsOneTimeOffer] = useState(false)
    const history = useHistory();
    const addGateAccessoriesToCartMutation = useMutation(authorizedPatchWithId, {
        onSuccess: (s) => {
            dispatch(creatorActions.updateItemDetailExtraAccessories(s.gateAccessories))
        }
    });

    const {data: client, isLoading} = useQuery(["client", user], () => authorizedGetById("/api/clients", user.userId), {
        keepPreviousData: true
    })
    const [options, setOptions] = useState([])
    const [companyOptions, setCompanyOptions] = useState([])
    useEffect(() => {
        if (client?.clients) {
            setOptions(client?.clients.map(c => ({value: JSON.stringify(c), label: c.name + " " + c.surname})))
            setCompanyOptions(client?.clients.map(c => ({
                value: JSON.stringify(c),
                label: c.company ? c.company : "Brak podanej firmy..."
            })))
        }
    }, [client]);


    useEffect(() => {
        setChosenItems([])
        setChosenAcc([])
    }, []);

    useEffect(() => {
        if (!!chosenItems.length || !!chosenAcc?.length) {
            updateCartPrice.mutate({
                url: `/api/${params.type}/price_from_cart`,
                data: {
                    items: chosenItems.map(e => e.id),
                    accessories: chosenAcc.map(e => e.id)
                }
            }, {
                onSuccess: d => {
                    setPriceData(prev => {
                        return (
                            {
                                ...prev,
                                priceData: d
                            }
                        )
                    })
                }
            })

            if (!chosenItems.length && !chosenAcc.length) {
                setPriceData(prev => {
                    return (
                        {
                            ...prev,
                            priceData: {
                                finalPrice: 0,
                                netPrice: 0,
                                regularNetPrice: 0,
                                regularPrice: 0
                            }
                        }
                    )
                })
            }
        }
    }, [chosenItems, chosenAcc]);

    const {
        cartPreviewDataToOffer, cartPreviewDataToOrder, cartPreviewOneTimeOffer, cartPreviewOfferInternalNumber,
        cartPreviewOrderInternalNumber, historyClientCompany, historyClientName, cartPreviewNoClients, historyClientAddress,
        historyClientCity, historyClientCountry, historyClientZipCode, historyClientPhone,
        cartPreviewClientCounty,cartPreviewClientEmail,cartPreviewOrderedProject,collapseText,
        expandText,cartItemsKTMCode,
        cartItemsKTMDescription,
        cartItemsKTMQty,
        cartItemsGateType,
        cartItemsWidth,
        cartItemsHeight,
        cartItemsDepth,
        cartItemsAreaLeft,
        cartItemsAreaRight,
        cartItemsLintel,
        cartItemsLeading,
        cartItemsDrive,
        cartItemsRoofAngle,
        cartItemsGateAccessories,
        summaryBoxNetPrice,
        cartPreviewDistributorPrice,
        language,
        summaryBoxGateAccessories,
        cartPreviewOrderExtraInfo,
        cartPreviewOfferExtraInfo,
        cartPreviewValidUntil
    } = useTranslations()

    return (
        <motion.section
            initial={{opacity: 0, y: 5}}
            animate={{opacity: 1, y: 0}}
            transition={{
                delay: .2,
                default: {
                    duration: .1
                }
            }
            }
            className="order-view">
            <div className="search-box show">
                <div className="search-box-title-container">
                    <h3>{isOrder ? cartPreviewDataToOrder : cartPreviewDataToOffer}</h3>
                    {user?.isDistributor && params.type === "offer_carts" && <div className="search-box-checkbox">
                        <Checkbox size={'sm'} onChange={() => setIsOneTimeOffer(prev => !prev)}/>
                        <p>{cartPreviewOneTimeOffer}</p>
                    </div>}
                </div>

                <div className="search-box-inputs">
                    <div>
                        <div>
                            <label
                                htmlFor="">{isOrder ? cartPreviewOrderInternalNumber : cartPreviewOfferInternalNumber}</label>
                            <input
                                onChange={e => {
                                    setInternalNumber(e.target.value)
                                }}
                                className="input-long input"
                                type="text"
                                placeholder={`${isOrder ? cartPreviewOrderInternalNumber : cartPreviewOfferInternalNumber}...`}/>
                        </div>
                        {!isOrder && <div>
                            <label htmlFor="">{historyClientName}</label>
                            {!isOneTimeOffer && user.isDistributor ? <Select
                                data={options}
                                value={receiverFromSelect}
                                className="input-long input"
                                placeholder={historyClientName}
                                nothingFound={cartPreviewNoClients}
                                searchable={true}
                                onChange={v => {
                                    setReceiverFromSelect(v)
                                }}
                                filter={(value, item) =>
                                    item?.value?.toLowerCase()?.includes(value.toLowerCase().trim())
                                }
                            /> : <input
                                value={receiver.receiverName ? receiver.receiverName : null}
                                onChange={e => setReceiver(prev => ({...prev, receiverName: e.target.value}))}
                                className="input-long"
                                type="text" placeholder={historyClientName}
                            />}
                        </div>}
                    </div>
                </div>
                {!isOrder && <>
                    <div className="search-box-inputs" style={{marginTop: 10}}>
                        <div>
                            <div>
                                <label htmlFor="">{historyClientCompany}</label>
                                {!isOneTimeOffer && user.isDistributor ? <Select
                                    data={companyOptions}
                                    className="input-long input"
                                    value={receiverFromSelect}
                                    placeholder={historyClientCompany}
                                    nothingFound={cartPreviewNoClients}
                                    searchable={true}
                                    onChange={v => {
                                        setReceiverFromSelect(v)
                                    }}
                                    filter={(value, item) =>
                                        item?.value?.toLowerCase()?.includes(value.toLowerCase().trim())
                                    }
                                /> : <input
                                    value={receiver.receiverCompany ? receiver.receiverCompany : null}
                                    onChange={e => setReceiver(prev => ({...prev, receiverCompany: e.target.value}))}
                                    className="input-long"
                                    type="text" placeholder={historyClientCompany}
                                />}
                            </div>
                            <div>
                                <label htmlFor="">{historyClientAddress}</label>
                                <input
                                    value={receiver.receiverAddress ? receiver.receiverAddress : null}
                                    onChange={e => setReceiver(prev => ({...prev, receiverAddress: e.target.value}))}
                                    className="input-long"
                                    type="text" placeholder={historyClientAddress}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="search-box-inputs" style={{marginTop: 10}}>
                        <div>
                            <div>
                                <label htmlFor="">{historyClientCity}</label>
                                <input
                                    value={receiver.receiverCity ? receiver.receiverCity : null}
                                    onChange={e => setReceiver(prev => ({...prev, receiverCity: e.target.value}))}
                                    className="input-long"
                                    type="text" placeholder={historyClientCity}
                                />
                            </div>
                            <div>
                                <label htmlFor="">{cartPreviewClientCounty}</label>
                                <input
                                    value={receiver.receiverState ? receiver.receiverState : null}
                                    onChange={e => setReceiver(prev => ({...prev, receiverState: e.target.value}))}
                                    className="input-long"
                                    type="text" placeholder={cartPreviewClientCounty}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="search-box-inputs" style={{marginTop: 10}}>
                        <div>
                            <div>
                                <label htmlFor="">{historyClientZipCode}</label>
                                <input
                                    value={receiver.receiverZipCode ? receiver.receiverZipCode : null}
                                    onChange={e => setReceiver(prev => ({...prev, receiverZipCode: e.target.value}))}
                                    className="input-long"
                                    type="text" placeholder={historyClientZipCode}
                                />
                            </div>
                            <div>
                                <label htmlFor="">{historyClientCountry}</label>
                                <input
                                    value={receiver.receiverCountry ? receiver.receiverCountry : null}
                                    onChange={e => setReceiver(prev => ({...prev, receiverCountry: e.target.value}))}
                                    className="input-long"
                                    type="text" placeholder={historyClientCountry}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="search-box-inputs" style={{marginTop: 10}}>
                        <div>
                            <div>
                                <label htmlFor="">{historyClientPhone}</label>
                                <input
                                    value={receiver.receiverPhone ? receiver.receiverPhone : null}
                                    onChange={e => setReceiver(prev => ({...prev, receiverPhone: e.target.value}))}
                                    className="input-long"
                                    type="text" placeholder={historyClientPhone}
                                />
                            </div>
                            <div>
                                <label htmlFor="">{cartPreviewClientEmail}</label>
                                <input
                                    value={receiver.receiverEmail ? receiver.receiverEmail : null}
                                    onChange={e => setReceiver(prev => ({...prev, receiverEmail: e.target.value}))}
                                    className="input-long"
                                    type="text" placeholder={cartPreviewClientEmail}
                                />
                            </div>
                        </div>
                    </div>
                </>}

            </div>
            <div className="content-items content-items__medium">
                <div className="items-container show">
                    <div className="accordion-container">
                        <div className="content-accordion__item">
                            <h3>{cartPreviewOrderedProject}</h3>
                        </div>
                        <div style={{height: 20, background: 'white'}}/>
                        {cart?.length > 0 && cart.map((item, index) => {
                            if (item?.gate?.id) {
                                return (
                                    <>
                                        <div className="content-accordion__item">
                                            <Checkbox
                                                checked={!!chosenItems.find(e => e.id === item.id)}
                                                onChange={e => {
                                                    const isChosen = chosenItems.find(e => e.id === item.id)
                                                    if (!isChosen) {
                                                        setChosenItems(prev => [...prev, item])
                                                    } else {
                                                        setChosenItems(prevState => prevState.filter(e => e.id !== item.id))
                                                    }
                                                }
                                                }/>
                                            <div>
                                                <div style={{display: 'flex', gap: 10}}>

                                                    <p className="content-accordion__title">{language === 'pl' ? item?.gate?.gateType?.name : item?.gate?.gateType?.translations ? item?.gate?.gateType?.translations[language].name : '-'}</p>
                                                </div>

                                                <div style={{display: 'flex', marginTop: 10, gap: 10}}>
                                                    <p style={{
                                                        textAlign: 'center',
                                                        color: '#1D446F',
                                                        fontWeight: 'bold',
                                                        fontSize: 12
                                                    }}>{summaryBoxNetPrice} {client.isDistributor ? cartPreviewDistributorPrice : null}:</p>
                                                    <p style={{textAlign: 'center', fontSize: 12}}>
                                                        {(item?.gate?.gateType?.name.toLowerCase().includes('przemysłowa') && !item?.gate?.leading?.priceType === 3) &&
                                                            <strong>Cena na zapytanie</strong>}
                                                        {!(item?.gate?.gateType?.name.toLowerCase().includes('przemysłowa') && !item?.gate?.leading?.priceType === 3) &&
                                                            <strong>{(item?.priceData.netPrice / 100).toFixed(2)} PLN</strong>}
                                                    </p>
                                                </div>
                                            </div>


                                            <div className="content-accordion__button"/>
                                            <div className="accordion-icons flex" style={{alignItems: 'center'}}>
                                                <p>{clickedItem !== item.id ? expandText : collapseText}</p>
                                                <img data-target="1"
                                                     onClick={(e) => {
                                                         setClickedItem(prev => {
                                                             if (prev === item.id) {
                                                                 return null
                                                             } else {
                                                                 return item.id
                                                             }
                                                         })
                                                     }}
                                                     className={`accordion-icons__expand  ${clickedItem !== item.id ? "" : "show"}`}
                                                     src={expandIcon}
                                                     alt="accordion icon"/>
                                            </div>
                                        </div>
                                        {<motion.div
                                            initial={{opacity: 0, y: 5}}
                                            animate={{opacity: 1, y: 0}}
                                            transition={{
                                                delay: .2,
                                                default: {
                                                    duration: .1
                                                }
                                            }
                                            }
                                            data-tab="1" className="content-accordion-expand">
                                            <div className="basket-container">
                                                <div className="basket-left">
                                                    <div className="basket-box">
                                                        <div className="basket-item">
                                                            <p className="basket-item__left">{cartItemsKTMCode}</p>
                                                            <p className="basket-item__right">{item?.gate?.ktmCode}</p>
                                                        </div>
                                                    </div>
                                                    <div className="basket-box">
                                                        <div className="basket-item">
                                                            <p className="basket-item__left">{cartItemsKTMDescription}</p>
                                                            <p className="basket-item__right">{item?.gate?.ktmCodeDescription}</p>
                                                        </div>
                                                    </div>
                                                    <div className="basket-box">
                                                        <div className="basket-item">
                                                            <p className="basket-item__left">{cartItemsKTMQty}</p>
                                                            <p className="basket-item__right">{item?.quantity}</p>
                                                        </div>
                                                    </div>
                                                    {clickedItem === item.id && <motion.div>
                                                        <div className="basket-box">
                                                            <div className="basket-item">
                                                                <p className="basket-item__left">{cartItemsGateType}</p>
                                                                <p className="basket-item__right">{item?.gate?.gateType?.name}</p>
                                                            </div>
                                                        </div>
                                                        <div className="basket-box">
                                                            <div className="basket-item">
                                                                <p className="basket-item__left">{cartItemsWidth}</p>
                                                                <p className="basket-item__right">{item?.gate?.width?.value} mm</p>
                                                            </div>
                                                            <div className="basket-item">
                                                                <p className="basket-item__left">{cartItemsHeight}</p>
                                                                <p className="basket-item__right">{item?.gate?.height?.value} mm</p>
                                                            </div>
                                                            <div className="basket-item">
                                                                <p className="basket-item__left">{cartItemsDepth}</p>
                                                                <p className="basket-item__right">{item?.gate?.depth} mm</p>
                                                            </div>
                                                            <div className="basket-item">
                                                                <p className="basket-item__left">{cartItemsAreaLeft}</p>
                                                                <p className="basket-item__right">{item?.gate?.rightArea} mm</p>
                                                            </div>
                                                            <div className="basket-item">
                                                                <p className="basket-item__left">{cartItemsAreaRight}</p>
                                                                <p className="basket-item__right">{item?.gate?.leftArea} mm</p>
                                                            </div>
                                                            <div className="basket-item">
                                                                <p className="basket-item__left">{cartItemsLintel}</p>
                                                                <p className="basket-item__right">{item?.gate?.lintel.value} mm</p>
                                                            </div>
                                                            {item?.gate?.drive && <div className="basket-item">
                                                                <p className="basket-item__left">{cartItemsDrive}</p>
                                                                <p className="basket-item__right">{item?.gate?.drive?.name}</p>
                                                            </div>}
                                                            <div className="basket-item">
                                                                <p className="basket-item__left">{cartItemsLeading}</p>
                                                                <p className="basket-item__right">{item?.gate?.leading?.name}</p>
                                                            </div>
                                                            {item?.gate?.roofAngle && <div className="basket-item">
                                                                <p className="basket-item__left">{cartItemsRoofAngle}</p>
                                                                <p className="basket-item__right">{item?.gate?.roofAngle}</p>
                                                            </div>}
                                                            {item?.gate?.componentItems.map(ci => {
                                                                return (
                                                                    <div className="basket-item">
                                                                        <p className="basket-item__left">{language === 'pl' ? ci.component.name : ci?.component?.translations ? ci?.component?.translations[language].name : '-' }</p>
                                                                        <p className="basket-item__right">{language === 'pl' ? ci.name : ci?.translations ? ci?.translations[language].name : '-'}</p>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>

                                                    </motion.div>}

                                                    {clickedItem === item.id && <div className="basket-box">
                                                        <div className="basket-item">
                                                            <p className="basket-item__left">{summaryBoxGateAccessories}</p>
                                                        </div>
                                                        {item?.gate?.gateAccessories?.map(i => {
                                                            return (
                                                                <div className="basket-item">
                                                                    <p className="basket-item__left">{language === 'pl' ? i?.gateAccessory?.category?.name : i?.gateAccessory?.category?.translations ? i?.gateAccessory?.category?.translations[language].name : '-'}</p>
                                                                    {i.position ? <p className="basket-item__right">
                                                                            {"Pozycja: " + i.position + " , Wyrówananie: "}
                                                                            {i.alignment === 1 &&
                                                                                <span>do prawej</span> || i.alignment === 2 &&
                                                                                <span>do lewej</span> || i.alignment === 3 &&
                                                                                <span>do środka</span> || i.alignment === 4 &&
                                                                                <span>do góry</span>}
                                                                        </p> :
                                                                        <p className="basket-item__right">{language === 'pl' ? i.gateAccessory.name : i?.gateAccessory?.translations ? i?.gateAccessory?.translations[language].name : '-'} {i?.gateAccessory?.name.toLocaleLowerCase() !== 'brak' ? `(x${i.quantity})` : ''}</p>}
                                                                </div>
                                                            )
                                                        })}
                                                    </div>}
                                                </div>

                                            </div>

                                        </motion.div>}
                                        {index !== cart.length - 1 &&
                                            <div style={{height: 10, background: '#F6F5F5'}}/>}
                                    </>
                                )
                            }
                            if (item?.accessory?.id) {
                                return (
                                    <React.Fragment key={item.id}>
                                        <div className="content-accordion__item">
                                            <Checkbox
                                                checked={!!chosenAcc.find(e => e.id === item.id)}
                                                onChange={e => {
                                                    const isChosen = chosenAcc.find(e => e.id === item.id)
                                                    if (!isChosen) {
                                                        setChosenAcc(prev => [...prev, item])
                                                    } else {
                                                        setChosenAcc(prevState => prevState.filter(e => e.id !== item.id))
                                                    }
                                                }
                                                }/>
                                            <div>
                                                <div style={{display: 'flex', gap: 10}}>
                                                    <p className="content-accordion__title">{language === 'pl' ? item?.accessory?.name :item?.accessory?.translations ? item?.accessory?.translations[language].name : '-' }</p>
                                                </div>

                                                <div style={{display: 'flex', marginTop: 10, gap: 10}}>
                                                    <p style={{
                                                        textAlign: 'center',
                                                        color: '#1D446F',
                                                        fontWeight: 'bold',
                                                        fontSize: 12
                                                    }}>{summaryBoxNetPrice}</p>
                                                    <p style={{textAlign: 'center', fontSize: 12}}>
                                                        <strong>{(item?.priceData.netPrice / 100).toFixed(2)} PLN</strong>
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="content-accordion__button"/>

                                            <div className="accordion-icons flex" style={{alignItems: 'center'}}>
                                                <p>{clickedItem !== item.id ? expandText : collapseText}</p>
                                                <img data-target="1"
                                                     src={expandIcon}
                                                     alt="accordion icon"
                                                     onClick={(e) => {
                                                         setClickedItem(prev => {
                                                             if (prev === item.id) {
                                                                 return null
                                                             } else {
                                                                 return item.id
                                                             }
                                                         })
                                                     }}
                                                     className={`accordion-icons__expand  ${clickedItem === item.id ? "" : "show"}`}/>
                                            </div>

                                        </div>
                                        {index !== cart.length - 1 && index !== 0 &&
                                            <div style={{height: 10, background: '#F6F5F5'}}/>}
                                        {clickedItem !== item.id &&
                                            <motion.div
                                                initial={{opacity: 0, y: 5}}
                                                animate={{opacity: 1, y: 0}}
                                                transition={{
                                                    delay: .2,
                                                    default: {
                                                        duration: .1
                                                    }
                                                }
                                                } data-tab="1" className="content-accordion-expand show">
                                                <div className="basket-container">
                                                    <div className="basket-left">
                                                        <div className="basket-box">
                                                            <div className="basket-item">
                                                                <p className="basket-item__left">{cartItemsKTMCode}</p>
                                                                <p className="basket-item__right">{item?.accessory?.ktm}</p>
                                                            </div>
                                                        </div>
                                                        <div className="basket-box">
                                                            <div className="basket-item">
                                                                <p className="basket-item__left">{cartItemsKTMDescription}</p>
                                                                <p className="basket-item__right">{item?.accessory?.ktmDescription}</p>
                                                            </div>
                                                        </div>
                                                        <div className="basket-box">
                                                            <div className="basket-item">
                                                                <p className="basket-item__left">{cartItemsKTMQty}</p>
                                                                <p className="basket-item__right">{item?.quantity}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </motion.div>}
                                    </React.Fragment>
                                )
                            }
                        })}
                    </div>
                </div>
            </div>
            <div className="comment-box show">
                <h3>{isOrder ? cartPreviewOrderExtraInfo : cartPreviewOfferExtraInfo}</h3>
                <div className="search-box-inputs">
                    <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                        <div>
                            <textarea
                                className="input-long"
                                placeholder={isOrder ? cartPreviewOrderExtraInfo : cartPreviewOfferExtraInfo}
                                onChange={e => setOrderNotes(e.target.value)}
                            />
                        </div>
                        {creatorItem.isOffer && <>
                            <h4>{cartPreviewValidUntil}</h4>
                            <div>
                                <input
                                    onChange={(e) => {

                                        setValidUntil(e.target.value)
                                    }}
                                    type="date"
                                />
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </motion.section>
    );
};

export default CartPreview;
