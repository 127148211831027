import React, {useCallback, useEffect, useMemo, useState} from 'react';
import okIcon from "../../../assets/icons/ok.png";
import eyeIcon from "../../../assets/icons/eye.png";
import deleteIcon from "../../../assets/icons/delete.png";
import {Route, Switch, useHistory, useLocation, useParams} from "react-router-dom";
import {motion} from "framer-motion";
import {useDispatch, useSelector} from "react-redux";
import {userActions} from "../../../store/slice/userSlice";
import {authActions} from "../../../store/slice/authSlice";
import useInput from "../../../hooks/useInput";
import Login from "../../../pages/Auth/Login/Login";
import {Loader} from "@mantine/core";
import {useQueryClient} from "react-query";
import useTranslations from "../../../hooks/useTranslations";

const UserDataForm = ({isFormSubmitted, success}) => {
    const [pageTitle, setPageTitle] = useState("")
    const [pageDescription, setPageDescription] = useState("")
    const location = useLocation();
    const dispatch = useDispatch();
    const locationName = location.pathname.split("/")[3]
    const user = useSelector(state => state.user);
    const auth = useSelector(state => state.auth);
    const [error, setError] = useState(null)
    const [logoutMessage, setLogoutMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const passRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    const [showPassword, setShowPassword] = useState(false);
    const {
        value: enteredNewEmail,
        isTouched: enteredNewEmailIsTouched,
        hasError: enteredNewEmailHasError,
        enteredValueChangeHandler: enteredNewEmailChangeHandler,
        inputBlurHandler: newEmailBlurHandler,
        reset: resetEnteredNewEmail
    } = useInput(enteredValue => enteredValue.trim().length > 5 && enteredValue.includes("@"));
    const {
        value: enteredRepeatedNewEmail,
        isTouched: enteredRepeatedNewEmailIsTouched,
        hasError: enteredRepeatedNewEmailHasError,
        enteredValueChangeHandler: enteredRepeatedNewEmailChangeHandler,
        inputBlurHandler: newRepeatedEmailBlurHandler,
        reset: resetEnteredRepeatedNewEmail
    } = useInput(enteredValue => enteredValue.trim().length > 5 && enteredValue.includes("@"));
    const {
        value: enteredNewPassword,
        isTouched: enteredNewPasswordIsTouched,
        hasError: enteredNewPasswordHasError,
        enteredValueChangeHandler: enteredNewPasswordChangeHandler,
        inputBlurHandler: newPasswordBlurHandler,
        reset: resetEnteredNewPassword
    } = useInput(enteredValue => passRegex.test(enteredValue));
    const {
        value: enteredNewRepeatedPassword,
        isTouched: enteredNewRepeatedPasswordIsTouched,
        hasError: enteredNewRepeatedPasswordHasError,
        enteredValueChangeHandler: enteredNewRepeatedPasswordChangeHandler,
        inputBlurHandler: newRepeatedPasswordBlurHandler,
        reset: resetEnteredNewRepeatedPassword
    } = useInput(enteredValue => passRegex.test(enteredValue));
    const {
        value: enteredPassword,
        isTouched: enteredPasswordIsTouched,
        hasError: enteredPasswordHasError,
        enteredValueChangeHandler: enteredPasswordChangeHandler,
        inputBlurHandler: PasswordBlurHandler,
        reset: resetEnteredPassword
    } = useInput(enteredValue => enteredValue?.trim().length > 7);

    const isFormValid = enteredNewPassword === enteredNewRepeatedPassword && !enteredNewPasswordHasError && !enteredNewRepeatedPasswordHasError;
    const {
        content,
        language,
        editData,
        editDataDescription,
        editEmail,
        editEmailDescription,
        editPassword,
        editPasswordDescription,
        userDataName,
        userDataPhone,
        userDataSurname,
        userDataCurrentPassword,
        userDataCurrentPasswordPlaceholder,
        userDataNewEmail,
        userDataRepeatEmail,
        saveButton,
        userDataLogoutMessage,
        userDataNewPassword,
        userDataRepeatNewPassword,
        userDataNewPasswordPlaceholder,
        userDataRepeatNewPasswordPlaceholder,
        userDataCurrentPasswordError,
        userDataAccountDeactivate
    } = useTranslations();


    const pathNameCheck = useCallback(() => {
        switch (locationName) {
            case "profile":
                setPageTitle(editData)
                setPageDescription(editDataDescription)
                break;
            case "email" :
                setPageTitle(editEmail)
                setPageDescription(editEmailDescription)
                break;
            case "password" :
                setPageTitle(editPassword)
                setPageDescription(editPasswordDescription)
                break;
        }
    }, [locationName, editData, editEmail, editPassword])

    useEffect(() => {
        pathNameCheck()
    }, [location, content, language])

    const handleProfileChanges = (e, data, toChange, method) => {
        e.preventDefault();
        setLogoutMessage(true)
        setIsLoading(true)
        setTimeout(() => {
            fetch(`${process.env.REACT_APP_API_URL}/api/clients/${user.userId}${toChange}`, {
                method: method,
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": method === "PATCH" ? "application/merge-patch+json" : "application/json",
                    "Authorization": `Bearer ${auth.token}`
                }
            }).then(response => {
                if (response.ok) {
                    isFormSubmitted(true)
                }
                return response.json()
            }).then(data => {
                if (data.detail === "email: This value is not a valid email address.") {
                    setError("Adres email jest nieprawidłowy")
                    return;
                }
                if (data.detail === "Current password is required") {
                    setError("Hasło jest nieprawidłowe")
                    return;
                }
                if (data.detail === "current_password: Current password is not correct.") {
                    setError("Podane aktualne hasło jest nieprawidłowe")
                    return;
                }
                if (data.code === 401 || data.detail === "Not Found") {
                    setError("Coś poszło nie tak, spróbuj ponownie")
                    return;
                }
                if (locationName === "profile") {
                    setIsLoading(false)
                } else {
                    setLogoutMessage(false)
                    dispatch(authActions.logOut())
                }
            })
        }, 3000)

    }

    return (
        <div className="dashboard-container">
            <motion.div
                initial={{opacity: 0, y: 5}}
                animate={{opacity: 1, y: 0}}
                transition={{
                    default: {
                        duration: .2
                    }
                }
                }
                className="dashboard-content">
                <div className="dashboard-content__left">
                    <h2
                        className="content-title">{pageTitle}</h2>
                    <p className="content-description">{pageDescription}</p>
                    <div className="flex gap-40">
                        <div className="content-form content-form__medium">
                            <Route path={"/user/data-edit/email"}>
                                <motion.form
                                    onSubmit={(e) => handleProfileChanges(e, {
                                        email: enteredNewEmail,
                                        current_password: enteredPassword
                                    }, "/change_email", "PATCH")}
                                    initial={{opacity: 0, y: 5}}
                                    animate={{opacity: 1, y: 0}}
                                    transition={{
                                        default: {
                                            duration: .2
                                        }
                                    }
                                    }
                                    className="form client-form">
                                    <div className="client-form__item">
                                        <div className="form--inp">
                                            <label htmlFor="email">{userDataNewEmail}</label>
                                            <input
                                                onChange={enteredNewEmailChangeHandler}
                                                type="email"
                                                id="email"
                                                placeholder="Email"/>
                                        </div>
                                        <div className="form--inp">
                                            <label htmlFor="repeatedEmail">{userDataRepeatEmail}</label>
                                            <input
                                                onChange={enteredRepeatedNewEmailChangeHandler}
                                                type="email"
                                                id="repeatedEmail"
                                                placeholder="Email"
                                            />
                                        </div>
                                    </div>
                                    {error && <p style={{color: "red", fontWeight: 500}}>{error}</p>}
                                    <div className="underline-form"></div>
                                    <div className="client-form__item client-form__item-last">
                                        <div className="form--inp">
                                            <label htmlFor="currentPassword">{userDataCurrentPassword}</label>
                                            <input
                                                onChange={enteredPasswordChangeHandler}
                                                type="password"
                                                id="currentPassword"
                                                placeholder={userDataCurrentPasswordPlaceholder}/>
                                        </div>
                                    </div>
                                    <div className="client-form__button">
                                        <button
                                            disabled={(enteredNewEmail.trim().length === 0 || enteredRepeatedNewEmail.trim().length === 0) || enteredNewEmail !== enteredRepeatedNewEmail}
                                            type={"submit"}>{isLoading ? <Loader color={"white"} size={22}/> : `${saveButton}`}<img
                                            src={okIcon} alt="background image"/></button>
                                    </div>
                                    {logoutMessage &&
                                        <p style={{textAlign: "center", marginTop: 20, fontSize: 14}}>{userDataLogoutMessage}
                                        </p>}

                                </motion.form>
                            </Route>
                            <Route path={"/user/data-edit/profile"}>
                                <motion.form
                                    initial={{opacity: 0, y: 5}}
                                    animate={{opacity: 1, y: 0}}
                                    transition={{
                                        default: {
                                            duration: .2
                                        }
                                    }
                                    }
                                    onSubmit={(e) => handleProfileChanges(e, {
                                        name: user.userName,
                                        surname: user.userSurname,
                                        phone: user.userPhone
                                    }, "", "PUT")}
                                    className="form client-form">
                                    <div className="client-form__item">
                                        <div className="form--inp">
                                            <label htmlFor="companyName">{userDataName}</label>
                                            <input
                                                value={user.userName}
                                                onChange={(e) => dispatch(userActions.updateUser({userName: e.target.value}))}
                                                type="text"
                                                id="userName"
                                                placeholder={userDataName}/>
                                        </div>
                                        <div className="form--inp">
                                            <label htmlFor="surname">{userDataSurname}</label>
                                            <input
                                                value={user.userSurname}
                                                onChange={(e) => dispatch(userActions.updateUser({userSurname: e.target.value}))}
                                                type="text"
                                                id="surname"
                                                placeholder={userDataSurname}/>
                                        </div>
                                    </div>
                                    <div className="client-form__item client-form__item-last">
                                        <div className="form--inp">
                                            <label htmlFor="clientPhone">{userDataPhone}</label>
                                            <input
                                                value={user.userPhone}
                                                onChange={(e) => dispatch(userActions.updateUser({userPhone: e.target.value}))}
                                                type="text"
                                                id="clientPhone"
                                                placeholder={userDataPhone}/>
                                        </div>
                                    </div>
                                    <div className="underline-form"></div>
                                    <div className="client-form__item client-form__item-last">
                                        <div className="form--inp">
                                            <label htmlFor="password">{userDataCurrentPassword}</label>
                                            <input
                                                onChange={enteredPasswordChangeHandler}
                                                type={showPassword ? "text" : "password"}
                                                id="password"
                                                placeholder={userDataCurrentPasswordPlaceholder}/>
                                            <img
                                                onClick={() => setShowPassword(!showPassword)}
                                                className="custom-icons"
                                                src={eyeIcon}
                                                alt="login icon"/>
                                        </div>
                                    </div>
                                    <div className="client-form__button">
                                        <button type={"submit"}>
                                            {isLoading ? <Loader color={"white"} size={22}/> : `${saveButton}`}
                                            {!isLoading && <img src={okIcon} alt="background image"/>}
                                        </button>
                                    </div>
                                </motion.form>
                            </Route>
                            <Route path={"/user/data-edit/password"}>
                                <motion.form
                                    onSubmit={(e) => handleProfileChanges(e, {
                                        password: enteredNewPassword,
                                        current_password: enteredPassword
                                    }, "/change_password", "PATCH")}
                                    initial={{opacity: 0, y: 5}}
                                    animate={{opacity: 1, y: 0}}
                                    transition={{
                                        default: {
                                            duration: .2
                                        }
                                    }
                                    } className="form client-form">
                                    <div className="client-form__item">
                                        <div className="form--inp">
                                            <label htmlFor="password">{userDataNewPassword}</label>
                                            <input
                                                onChange={enteredNewPasswordChangeHandler}
                                                type="password"
                                                id="password"
                                                placeholder={userDataNewPasswordPlaceholder}/>
                                            {enteredNewPasswordHasError && enteredNewPasswordIsTouched &&
                                                <p style={{color: "red"}}>{userDataCurrentPasswordError}</p>}
                                        </div>
                                        <div className="form--inp">
                                            <label htmlFor="new_password">{userDataRepeatNewPassword}</label>
                                            <input
                                                onChange={enteredNewRepeatedPasswordChangeHandler}
                                                type="password"
                                                id="new_password"
                                                placeholder={userDataRepeatNewPasswordPlaceholder}/>
                                        </div>
                                    </div>
                                    {(enteredNewPassword.trim().length === 0 || enteredNewRepeatedPassword.trim().length === 0) || enteredNewPassword !== enteredNewRepeatedPassword &&
                                        <p className="text-center" style={{color: "red", fontWeight: 500}}>{userDataCurrentPasswordError}</p>}
                                    <div className="underline-form"></div>
                                    <div className="client-form__item client-form__item-last">
                                        <div className="form--inp">
                                            <label htmlFor="clientPhone">{userDataCurrentPassword}</label>
                                            <input
                                                onChange={enteredPasswordChangeHandler}
                                                type="password"
                                                id="clientPhone"
                                                placeholder={userDataCurrentPasswordPlaceholder}/>
                                        </div>
                                    </div>
                                    {error && <p style={{color: "red", fontWeight: 500}}>{error}</p>}
                                    <div className="client-form__button">
                                        <button
                                            disabled={(enteredNewPassword.trim().length === 0 || enteredNewRepeatedPassword.trim().length === 0) || enteredNewPasswordHasError || enteredNewPassword !== enteredNewRepeatedPassword}
                                            type={"submit"}>
                                            {isLoading ? <Loader color={"white"} size={22}/> : `${saveButton}`}
                                            <img src={okIcon} alt="background image"/>
                                        </button>
                                    </div>
                                    {logoutMessage &&
                                        <p style={{textAlign: "center", marginTop: 20, fontSize: 14}}>{userDataLogoutMessage}
                                        </p>}
                                </motion.form>
                            </Route>
                            <Route path={"/user/data-edit/deactivate"}>
                                <motion.form
                                    initial={{opacity: 0, y: 5}}
                                    animate={{opacity: 1, y: 0}}
                                    transition={{
                                        default: {
                                            duration: .2
                                        }
                                    }
                                    }
                                    action="" className="form client-form">
                                    <div className="client-form__item">
                                        <div className="form--inp">
                                            <label htmlFor="clientPhone">{userDataCurrentPassword}</label>
                                            <input type="password" id="clientPhone" placeholder={userDataCurrentPasswordPlaceholder}/>
                                        </div>
                                    </div>
                                    <div className="client-form__button">
                                        <a
                                            onClick={e => handleProfileChanges(e, {isActive: false}, "/change_active_status", "PATCH")}
                                            style={{maxWidth: "50%"}} href="#" className="btn-danger">
                                            {userDataAccountDeactivate}
                                            <img src={deleteIcon} alt="background image"/>
                                        </a>
                                    </div>
                                </motion.form>
                            </Route>
                        </div>
                    </div>
                </div>
                <div className="dashboard-content__right">
                </div>
            </motion.div>
        </div>
    );
};

export default UserDataForm;
