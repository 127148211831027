import React from 'react';
import './Footer.scss';
import footerLogo from '../../assets/logo-footer.png';
import footerBackgroundImage from '../../assets/image12.png';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Select} from "@mantine/core";
import {contentActions} from "../../store/slice/contentSlice";
import {uiActions} from "../../store/slice/uiSlice";
import useTranslations from "../../hooks/useTranslations";

const Footer = () => {
    const {
        footerPrintReturn,
        footerSecretariat,
        footerExport,
        footerSteelSale,
        footerReturns,
        footerMarketing,
        footerWoodSale
    } = useTranslations();
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const language = useSelector(state => state.ui.language);
    return (
        <footer>
            <div className="footer-container">
                <div className="row">
                    <div className="footer-logo">
                        <img className="login-logo" src={footerLogo} alt="logo"/>
                    </div>
                    <div className="bg-image">
                        <img src={footerBackgroundImage} alt=""/>
                    </div>
                </div>
                <div className="row">
                    <div className="footer-row">
                        <div className="footer-row__item">
                            <div>
                                <strong>Stalprodukt-Zamość Sp. z o.o.</strong>
                                <p>ul. Kilińskiego 86</p>
                                <p>22-400 Zamość</p>
                            </div>
                            <div>
                                <p>KRS: 0000101449</p>
                                <p>NIP: 9222191790</p>
                                <p>Regon: 950329300</p>
                            </div>
                        </div>

                        <div className="footer-row__item">
                            <strong>{footerWoodSale}
                            </strong>
                            <p>+48 84 638 97 92</p>
                            <p>+48 84 638 97 85</p>
                            <p>+48 84 638 97 81</p>
                            <p>zapytania@futryna.com.pl</p>
                            <p>Poniedziałek – Piątek: 7:00 – 16:00</p>
                        </div>
                        <div className="footer-row__item">
                            <strong>{footerExport}</strong>
                            <p>+48 539 862 826 (en, ru)</p>
                            <p>export@futryna.com.pl</p>
                            <p>Poniedziałek – Piątek: 7:00 – 16:00</p>
                        </div>
                        <div className="footer-row__item">
                            <strong>{footerSteelSale}
                            </strong>
                            <p>84 638 97 84</p>
                            <p>84 638 97 83</p>
                            <p>stal@futryna.com.pl</p>
                            <p>Poniedziałek – Piątek: 7:00 – 16:00</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="footer-row">
                        <div className="footer-row__item">
                            <div>
                                <strong>{footerSecretariat}</strong>
                                <p>+48 84 639 34 41</p>
                                <p>+48 84 638 97 71</p>
                                <p>sekretariat@futryna.com.pl</p>
                                <p>Poniedziałek – Piątek: 7:00 – 15:00</p>
                            </div>
                        </div>
                        <div className="footer-row__item">
                            <div>
                                <strong>{footerReturns}</strong>
                                <p>84 638 97 77</p>
                                <p>660 449 127</p>
                                <p>reklamacje@futryna.com.pl</p>
                            </div>
                            <div>
                                <p>{footerPrintReturn}</p>
                            </div>
                        </div>
                        <div className="footer-row__item">
                            <strong>{footerMarketing}</strong>
                            <p>marketing@futryna.com.pl</p>
                        </div>
                        <div className="footer-row__item">
                            <div>
                                <strong>Polska Technika Zabezpieczeń Sp. z o. o.

                                </strong>
                                <p>ul. Sasankowa 8</p>
                                <p>05-070 Sulejówek</p>
                            </div>
                            <div>
                                <p>22 813 03 03</p>
                                <p>601 780 333</p>
                                <p>www.ptz.pl</p>
                                <p>ptz@ptz.pl</p>
                            </div>
                        </div>
                    </div>
                </div>
                <p className="footer-bottom">Sąd Rejonowy Lublin Wschód w Lublinie z Siedzibą w Świdniku, VI Wydział
                    Gospodarczy
                    Krajowego Rejestru Sądowego. Wysokość kapitału zakładowego: 2 450 000,00 zł <br/> 2021
                    Stalprodukt,{user.userName &&
                        <Link style={{position: "relative"}} to={"/policy"}>Polityka prywatności</Link>}</p>
            </div>
        </footer>
    );
};

export default Footer;
