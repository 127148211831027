import React, {useState} from 'react';
import okIcon from '../../../assets/icons/ok.png';
import "./DataEdit.scss";
import topIcon from "../../../assets/icons/login-icon-top-2.png";
import dashboardIcon from "../../../assets/icons/dashboard.png";
import {Link} from "react-router-dom";
import UserDataForm from "./UserDataForm";
import {motion} from "framer-motion";

const UserDataEdit = () => {
    const [success, setSuccess] = useState(false);

    const isFormSubmitted = (data) => {
        setSuccess(data)
    }


    return (
        <>
            {!success ?
                <UserDataForm isFormSubmitted={isFormSubmitted} success={success}/> :
                <section className="dashboard action-ready">
                    <motion.div
                        initial={{opacity: 0, y: 5}}
                        animate={{opacity: 1, y: 0}}
                        transition={{
                            default: {
                                duration: .2
                            }
                        }
                        }
                        className="container">

                        <img className="section-top-icon" src={topIcon} alt="login icon"/>
                        <h2 className="dashboard-title">Akcja gotowa</h2>
                        <p>Bramy garażowe segmentowe znajdują zastosowanie w budownictwie jedno- i wielorodzinnym.</p>
                        <Link to="/" className="btn btn-primary">Wróć do panelu starowego <img
                            className="custom-icons" src={dashboardIcon} alt="login icon"/></Link>
                    </motion.div>
                </section>}
        </>
    );
};

export default UserDataEdit;