import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {creatorActions} from "../../../store/slice/gateCreatorSlice";
import {useMutation, useQuery} from "react-query";
import {authorizedGet, authorizedPost, authorizedPutWithId} from "../CreatorFunctions";
import {Loader} from "@mantine/core";
import {summaryBoxActions} from "../../../store/slice/summarySlice";
import {motion} from "framer-motion";
import useTranslations from "../../../hooks/useTranslations";

const Drive = () => {
    const dispatch = useDispatch();
    const creatorItem = useSelector(state => state.gateCreator);
    const [chosenDrive, setChosenDrive] = useState(creatorItem?.drive?.id)


    const {
        data: gateType,
        isLoading
    } = useQuery(["gateType", creatorItem], () => authorizedGet(`/api/gate_types${creatorItem?.gateType?.id ? "/" + creatorItem?.gateType?.id : ""}`), {
        keepPreviousData: true
    });
    const {data: chosenGateTypeAllDrives} = useQuery("chosenGateTypeDrives", () => authorizedGet(`/api/gate_types/${creatorItem?.gateType?.id}/drive_groups`), {
        placeholderData: []
    })
    const driveGroups = chosenGateTypeAllDrives.driveGroups || [];

    const updateGateMutation = useMutation(authorizedPutWithId, {
        onSuccess: (d) => {
            dispatch(creatorActions.updateCreator(d))
        }
    })
    const [filteredDriveGroups, setFilteredDriveGroups] = useState([])
    const gateSurface = (creatorItem.height.value * creatorItem.width.value) / 1000000;
    useEffect(() => {
        const filteredDrives = [];
        for (const driveGroup of driveGroups) {
            for (const drive of driveGroup.drives) {
                for (const leading of drive.leadings) {
                    if (leading?.id !== creatorItem?.leading?.id) continue
                    if (!(drive?.minHeight <= creatorItem?.lintel?.value && drive?.maxHeight >= creatorItem?.lintel?.value)) continue
                    if (!(drive?.minTotalHeight <= creatorItem?.height?.value && drive?.maxTotalHeight >= creatorItem?.height?.value)) continue
                    if (!(drive?.depth <= creatorItem?.depth && drive?.leftArea <= creatorItem?.leftArea && drive?.rightArea <= creatorItem?.rightArea && drive?.maximalSurface >= gateSurface)) continue

                    filteredDrives.push(drive)
                }
            }
        }
        setFilteredDriveGroups(filteredDrives)
    }, [driveGroups, gateSurface, creatorItem])

    const {
        chooseButton, chosenButton,
        driveNoDrives,
        language,
    } = useTranslations();

    return (
        <div className="flex">
            <div className="content-items content-items__medium content-items__small p-24">
                {isLoading && <div style={{width: "100%", textAlign: "center", padding: 20}}><Loader/></div>}
                {!isLoading && filteredDriveGroups.length === 0 &&
                    <p>{driveNoDrives}</p>}
                {!isLoading && filteredDriveGroups?.length > 0 && <div className="items-container gap-24 show">
                    {filteredDriveGroups?.sort((a, b) => a.driveGroupOrder - b.driveGroupOrder).map(drive => {
                        return (
                            <motion.div
                                initial={{
                                    opacity: 0
                                }}
                                animate={{
                                    opacity: 1
                                }}
                                style={{
                                    backgroundImage: drive?.image?.contentUrl ? `url(${process.env.REACT_APP_API_UPLOADS + drive?.image?.contentUrl})` : null,
                                    backgroundSize: "cover",
                                    backgroundBlendMode: "screen",
                                    flex: 1
                                }}
                                key={drive.id}
                                className="content-item content-item1 content-item__drive">
                                <p className="content-item__title">{language === 'pl' ? drive.name : drive?.translations ? drive?.translations[language].name : '-'}</p>
                                <button
                                    onClick={() => {
                                        setChosenDrive(drive.id)
                                        dispatch(creatorActions.updateCreator({
                                            ...creatorItem,
                                            drive: drive
                                        }))
                                        updateGateMutation.mutate({
                                            url: '/api/gates',
                                            data: {
                                                drive: drive?.id
                                            },
                                            id: creatorItem.id
                                        })

                                    }}
                                    className={`content-item__link ${chosenDrive === drive.id ? "chosen" : ""}`}
                                >{chosenDrive !== drive.id ? chooseButton : chosenButton}</button>
                            </motion.div>
                        )
                    })}
                </div>}
            </div>
        </div>
    );
};

export default Drive;
