import React from 'react';
import {Link} from "react-router-dom";
import logoPassForget from '../../assets/icons/icon-register-top.png'

const PasswordForgotMessage = () => {
    return (
        <section className="section-login-form section-reset-password section-reset-password-1">
            <form action="" className="form">
                <img src={logoPassForget} alt="login form icon"/>
                    <h2>Resetowanie hasła</h2>
                    <div className="form--inp">
                        <p>Na Twój adres email wysłaliśmy informacje związane ze zmianą hasła.</p>
                    </div>
                    <div className="form--inp">
                        <p>Postępuj zgodnie z instrukcjami zawartymi w wiadomości</p>
                    </div>

                    <div className="form__underline"></div>

                    <p className="mb-0 body-m text-center"><Link to="/login">Wróć do logowania</Link></p>
            </form>
        </section>
    );
};

export default PasswordForgotMessage;