import {createSlice} from "@reduxjs/toolkit";

const initialState = []

const clientSlice = createSlice({
    name: "clients",
    initialState,
    reducers: {
        replaceClients(state, action) {
           return state = action.payload
        },
        addNewClient(state, action) {
            const newClient = action.payload;
            state.push(newClient)
        }
    }
});

export const clientSliceAction = clientSlice.actions;

export default clientSlice.reducer;