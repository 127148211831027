import {createSlice} from "@reduxjs/toolkit";

const initialRendersState = {
    background: null,
    renders: {
        gate: null,
        panel: null,
        gateAccessories: []
    },
};

const visualizationSlice = createSlice({
        name: "visualization",
        initialState: initialRendersState,
        reducers: {
            addGateRender(state, action) {
                state.renders.gate = action.payload
            },
            addPanelRender(state, action) {
                state.renders.panel = action.payload
            },
            addGateAccessoriesRender(state, action) {
                const findIndex = state.renders.gateAccessories.findIndex(gA => gA.accType === action.payload.accType)
                if (findIndex >= 0) {
                    state.renders.gateAccessories[findIndex] = action.payload
                } else {
                    state.renders.gateAccessories.push(action.payload)
                }
            },
            editGateAccessoriesRenderPosition(state, action) {
                const findIndex = state.renders.gateAccessories.findIndex(gA => gA.accType === action.payload.accType)
                if (findIndex >= 0) {
                    state.renders.gateAccessories[findIndex] = {
                        ...state.renders.gateAccessories[findIndex],
                        accHorizonatalPos: action.payload.accHorizonatalPos
                    }
                }
            },
            addImgToVisualization(state, action) {
                state.img = [...state.img, action.payload]
            },
            addBackgroundToVisualization(state, action) {
                state.background = action.payload
            },
            resetBackgroundToVisualization(state) {
                state.background = null
            },
            resetVisualization(state) {
                return state = initialRendersState
            },
            resetGateAccessoriesVisualization(state) {
                state.renders.gateAccessories = []
            },
            deleteImgVisualization(state, action) {
                state.renders.gateAccessories = state.renders.gateAccessories.filter(e => e.acc !== action.payload)
            }
        }
    }
)

export const visualizationActions = visualizationSlice.actions;

export default visualizationSlice.reducer;
