import React, {useEffect, useState} from 'react';
import eyeIcon from "../../../assets/icons/eye.png";
import expand from "../../../assets/icons/expand.png";
import {useDispatch} from "react-redux";
import {motion} from "framer-motion";
import {creatorActions} from "../../../store/slice/gateCreatorSlice";
import {useMutation} from "react-query";
import {authorizedPutWithId} from "../CreatorFunctions";
import {uiActions} from "../../../store/slice/uiSlice";
import {visualizationActions} from "../../../store/slice/visualizationSlice";
import useTranslations from "../../../hooks/useTranslations";

const Panel = (props) => {
    const {chooseButton, chosenButton, panelNoColors, noElementsToDisplay} = useTranslations()
    const {colors, creatorItem, stampings} = props;
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const colorFromCreator = creatorItem.componentItems.find(c => c?.component?.name === 'Kolor')
    const stampingFromCreator = creatorItem.componentItems.find(c => c?.component?.name === 'Tłoczenie')
    const [chosenColor, setChosenColor] = useState(colorFromCreator && stampingFromCreator ? {
        color: colorFromCreator,
        stamping: stampingFromCreator.id
    } : null);

    const showSummary = (stamping) => {
        dispatch(uiActions.chosenAccessory(stamping))
        dispatch(creatorActions.showDescription())
    }

    const showAccrodionHandler = () => {
        setOpen(!open)
    }


    const filteredColors = colors ? colors[0]?.componentItems?.filter(compItem => {
        return compItem?.gateTypes?.some(gate => gate?.id === creatorItem?.gateType?.id)
    }).filter(e => !e.isHidden) : [];

    const filteredStamping = stampings ? stampings[0]?.componentItems?.filter(item => {
        return filteredColors?.filter(i => i?.componentItemsValues?.some(color => {
            return color.id === item.id
        }))
    }).filter(e => !e.isHidden) : [];

    const [clickedItem, setClickedItem] = useState(filteredStamping[0]?.id);

    const updateGateMutation = useMutation(authorizedPutWithId, {
        onSuccess: (d) => {
            dispatch(creatorActions.updateCreator(d))
        }
    })

    useEffect(() => {
        if (chosenColor?.color?.colorItemRenders) {
            dispatch(visualizationActions.addPanelRender(chosenColor?.color?.colorItemRenders.find(color => color.name.split("_")[0] === chosenColor.stamping)?.render?.contentUrl))
        }
    }, [chosenColor])

    const {language} = useTranslations()

    return (
        <div className="content-items content-items__medium">
            <div className="items-container show">
                {filteredStamping.length === 0 && <p>{noElementsToDisplay}</p>}
                {filteredStamping.length > 0 && filteredStamping.filter(e => e.gateTypes.some(e => e.id === creatorItem.gateType.id)).map((stamping, index) => {
                    if (filteredColors.filter(color => color.componentItemsValues.some(item => item.id === stamping.id)).length > 0) {

                        return <div key={stamping.id} className="accordion-container">
                            <div className="content-accordion__item">
                                <p className="content-accordion__title">{language === 'pl' ? stamping.name : stamping?.translations ? stamping.translations[language].name : '-'}</p>
                                <div className="accordion-icons flex">
                                    <img className="accordion-icons__eye" src={eyeIcon}
                                         onClick={() => showSummary(stamping)}
                                         alt="accordion icon"/>
                                    <img
                                        onClick={(e) => {
                                            setClickedItem(clickedItem === stamping.id ? null : stamping.id)
                                        }}
                                        data-target="1"
                                        className={`accordion-icons__expand ${clickedItem === stamping.id ? "show" : ""}`}
                                        src={expand}
                                        alt="accordion icon"/>
                                </div>
                            </div>
                            {clickedItem === stamping.id && <motion.div
                                initial={{opacity: 0, y: 5}}
                                animate={{opacity: 1, y: 0}}
                                transition={{
                                    default: {
                                        duration: .1
                                    }
                                }
                                }
                                className="content-accordion-expand show">
                                {filteredColors.filter(color => color.componentItemsValues.some(item => item.id === stamping.id)).length > 0 && filteredColors.filter(color => color.componentItemsValues.some(item => item.id === stamping.id)).map(color => {
                                    return (
                                        <div onClick={showAccrodionHandler} key={color.id} className="accordion-item">
                                            <img src={`${process.env.REACT_APP_API_UPLOADS}${color?.image?.contentUrl}`}
                                                 alt="small icon"/>
                                            <p className="accordion-item__title">{language === 'pl' ? color.name : color?.translations ? color.translations[language].name : '-'}</p>
                                            <a onClick={() => {
                                                setChosenColor({
                                                    color: color,
                                                    stamping: clickedItem
                                                })
                                                updateGateMutation.mutate({
                                                    url: '/api/gates',
                                                    data: {
                                                        componentItems: [stamping.id, color.id, color?.componentItemsValues?.find(e => e?.component?.name === "Struktura").id]
                                                    },
                                                    id: creatorItem.id
                                                })
                                            }}
                                               className={`content-item__link ${(chosenColor?.color?.id === color.id && chosenColor?.stamping === stamping.id) ? "chosen" : null} `}>
                                                {(chosenColor?.color?.id === color.id && chosenColor?.stamping === stamping.id) ? chosenButton : chooseButton}
                                            </a>
                                        </div>
                                    )
                                })}
                                {filteredColors.filter(color => color.componentItemsValues.some(item => item.id === stamping.id)).length === 0 &&
                                    <p>{panelNoColors}</p>}
                            </motion.div>}
                        </div>
                    }

                })}
            </div>
        </div>
    )
        ;
};

export default Panel;
