import React from 'react';
import {useQuery} from "react-query";
import {Loader} from "@mantine/core";
import {findGateById} from "../History/HistoryFunctions";

const OrderOfferCustomGateDetails = ({gate}) => {
    const accessToken = localStorage.getItem("token");
    const {
        data: gateDetailed,
        isLoading
    } = useQuery(["gateDetails", gate.id], () => findGateById(accessToken, gate.gate))

    return (
        <>
            {isLoading &&
            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}><Loader/></div>}
            {!isLoading && <>
                <div className="basket-box">
                    <div className="basket-item">
                        <p className="basket-item__left">Typ bramy</p>
                        <p className="basket-item__right">{gateDetailed?.gateType?.name || "-"}</p>
                    </div>
                </div>
                <div className="basket-box">
                    <div className="basket-item">
                        <p className="basket-item__left">Szerokość</p>
                        <p className="basket-item__right">{gateDetailed?.width?.value + " mm" || "-"}</p>
                    </div>
                    <div className="basket-item">
                        <p className="basket-item__left">Wysokość</p>
                        <p className="basket-item__right">{gateDetailed?.height?.value + " mm" || "-"}</p>
                    </div>
                    <div className="basket-item">
                        <p className="basket-item__left">Głębokość</p>
                        <p className="basket-item__right">{gateDetailed?.depth + " mm" || "-"}</p>
                    </div>
                </div>

                <div className="basket-box">
                    {gateDetailed?.componentItems?.map(detail => (
                        <div className="basket-item">
                            <p className="basket-item__left">{detail?.component?.name || "-"}</p>
                            <p className="basket-item__right">{detail?.name || "-"}</p>
                        </div>
                    ))}
                </div>
                {gateDetailed?.gateAccessories.length > 0 && <div className="basket-box">
                    {gateDetailed?.gateAccessories?.map(detail => (
                        <div className="basket-item">
                            <p className="basket-item__left">{detail?.component?.name || "-"}</p>
                            <p className="basket-item__right">{detail?.name || "-"}</p>
                        </div>
                    ))}
                </div>}
            </>}
        </>

    )
        ;
}
;

export default OrderOfferCustomGateDetails;