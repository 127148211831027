import React, {useCallback, useEffect, useState} from 'react';
import image from "../../assets/S.png";
import "./History.scss";
import "../Cart/Cart.scss";
import {motion} from "framer-motion";
import arrowUpIcon from "../../assets/icons/expanded.png";
import arrowDownIcon from "../../assets/icons/expand.png";
import copyIcon from "../../assets/icons/copy.png";
import downloadIcon from "../../assets/icons/download.png";
import addIcon from "../../assets/icons/add.png";
import {Link, useHistory, useParams} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {authorizedGet, authorizedPatchWithId, authorizedPost, authorizedPutWithId} from "../Creator/CreatorFunctions";
import {Loader, Pagination, Select} from "@mantine/core";
import {useDispatch, useSelector} from "react-redux";
import {creatorActions} from "../../store/slice/gateCreatorSlice";
import {cartActions} from "../../store/slice/cartSlice";
import CustomGateDetails from "./CustomGateDetails";
import {summaryBoxActions} from "../../store/slice/summarySlice";
import {findGateById} from "./HistoryFunctions";
import CustomAccessories from "./CustomAccessories";
import {sortByDate} from "../../utilis/utilis";
import {useDebouncedValue} from "@mantine/hooks";
import okIcon from "../../assets/icons/ok.png";
import CustomHistoryItemFooter from "./CustomHistoryItemFooter";
import useTranslations from "../../hooks/useTranslations";

const History = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const queryClient = useQueryClient();
    const content = queryClient.getQueryData("contents")
    const [clickedItem, setClickedItem] = useState(null);
    const [clickedGated, setClickedGated] = useState(null);
    const accessToken = localStorage.getItem("token");
    const [searchValue, setSearchedValue] = useState('')
    const [chosenStatus, setChosenStatus] = useState('')
    const [debauncedSearchValue] = useDebouncedValue(searchValue, 1000)
    const {
        cartItemsCompany,
        cartStatus,
        cartName,
        cartCreatedAt,
        historyOrderTitle,
        historyOfferTitle,
        cartSearchPlaceholder,
        historyFilterPlaceholder,
        historyDescription,
        historyFilterStatusSentToDistributor,
        historyFilterStatusConfirmed,
        historyFilterStatusCanceled,
        historyFilterStatusSent,
        historyFilterStatusConfirmedByDistributor,
        historyFilterStatusNew,
        historyEmpty,
        historyOrderNumber,
        historyOfferNumber,
        historyValidUntil,
        historyNetValue,
        historyRealizationDate,
        historyDistributor,
        historyOffer,
        historyClientCompany,
        historyClientName,
        historyOrderInternalNumber,
        historyOfferInternalNumber,
        historyClientAddress,
        historyClientZipCode,
        historyClientCity,
        historyClientCountry,
        historyClientPhone,
        historyClientEmail,
        historyNewProject,
        historyFilterStatusChanged
    } = useTranslations();
    const orderStatus = ["-", historyFilterStatusNew, historyFilterStatusConfirmedByDistributor, historyFilterStatusSent, historyFilterStatusConfirmed, historyFilterStatusCanceled, historyFilterStatusSentToDistributor];
    const offerStatus = ["-", historyFilterStatusNew, historyFilterStatusChanged,];
    const gateAllOrderHistory = async (page = 1) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/${params.type}?pagination=false}`, {
            method: "GET",
            headers: {
                'Accept': 'application/ld+json',
                "Authorization": `Bearer ${accessToken}`
            }
        });

        if (!response.ok) {
            throw new Error("Coś poszło nie tak")
        }

        const data = await response.json()

        return {
            data: data['hydra:member'],
            totalItems: data['hydra:totalItems']
        }
    }
    const gateOrderHistory = async (page = 1) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/${params.type}?page=${page}}`, {
            method: "GET",
            headers: {
                'Accept': 'application/ld+json',
                "Authorization": `Bearer ${accessToken}`
            }
        });

        if (!response.ok) {
            throw new Error("Coś poszło nie tak")
        }

        const data = await response.json()

        return {
            data: data['hydra:member'],
            totalItems: data['hydra:totalItems']
        }
    }
    const [chosenGate, setChosenGate] = useState(null)
    const [page, setPage] = useState(1)
    const {
        data: orderAll,
    } = useQuery(["orderAll"], () => gateAllOrderHistory(), {
        retry: false,
        refetchOnWindowFocus: false
    })

    const {
        data: order,
        isLoading,
        isFetching
    } = useQuery(["order", page], () => gateOrderHistory(page), {
        retry: false,
        refetchOnWindowFocus: false
    })
    const totalPages = Math.ceil(order?.totalItems / 30);
    const [historyOrders, setHistoryOrders] = useState([])
    useEffect(() => {
        if (chosenStatus && debauncedSearchValue && orderAll) {
            const tempHistory = orderAll.data.filter(order => order.status === chosenStatus)
            const tempSearchedHistory = tempHistory.filter(order => order?.clientNumber?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                || order?.orderNumber?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                || order?.offerNumber?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                || new Date(order?.createdAt).toLocaleDateString().toLowerCase().includes(debauncedSearchValue.toLowerCase())
                || order?.receiverZipCode?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                || order?.receiverPhone?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                || order?.receiverName?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                || order?.receiverEmail?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                || order?.receiverCountry?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                || order?.receiverCompany?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                || order?.receiverCity?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                || order?.receiverAddress?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                || order?.client?.city?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                || order?.client?.company?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                || order?.client?.companyShort?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                || order?.client?.email?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                || order?.client?.internalNumber?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                || order?.client?.name?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                || order?.client?.phone?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                || order?.client?.street?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                || order?.client?.surname?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                || order?.client?.vatNumber?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                || order?.client?.zipCode?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
            )
            setHistoryOrders({data: tempSearchedHistory, totalItems: tempHistory.length})
        } else if (chosenStatus && orderAll) {
            const tempHistory = orderAll.data.filter(order => order.status === chosenStatus)
            setHistoryOrders({data: tempHistory, totalItems: tempHistory.length})
        } else if (debauncedSearchValue && orderAll) {
            const tempHistory = orderAll.data.filter(order => {
                    return order?.clientNumber?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                        || order?.orderNumber?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                        || order?.offerNumber?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                        || new Date(order?.createdAt).toLocaleDateString().toLowerCase().includes(debauncedSearchValue.toLowerCase())
                        || order?.receiverZipCode?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                        || order?.receiverPhone?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                        || order?.receiverName?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                        || order?.receiverEmail?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                        || order?.receiverCountry?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                        || order?.receiverCompany?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                        || order?.receiverCity?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                        || order?.receiverAddress?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                        || order?.client?.city?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                        || order?.client?.company?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                        || order?.client?.companyShort?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                        || order?.client?.email?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                        || order?.client?.internalNumber?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                        || order?.client?.name?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                        || order?.client?.phone?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                        || order?.client?.street?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                        || order?.client?.surname?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                        || order?.client?.vatNumber?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                        || order?.client?.zipCode?.toLowerCase().includes(debauncedSearchValue.toLowerCase())
                }
            )
            setHistoryOrders({data: tempHistory, totalItems: tempHistory.length})
        } else if (order) {
            setHistoryOrders(order)
        }
    }, [order, chosenStatus, debauncedSearchValue]);

    useEffect(() => {
        const nextPage = page + 1;

        if (nextPage <= totalPages) {
            queryClient.prefetchQuery(
                ["order", nextPage],
                () => gateOrderHistory(nextPage))
        }
    }, [page, queryClient, totalPages])

    useEffect(() => {
        setPage(1)
    }, [params.type])
// const historyOrders?.data
    return (
        <div className="history-container dashboard-container dashboard-container--big">
            <div className="dashboard-content">
                <div className="dashboard-content__left">
                    <h2 className="content-title">{params.type === "orders" ? historyOrderTitle : historyOfferTitle}</h2>

                    <p className="content-description">{historyDescription}</p>
                    {!isFetching && <div className="custom-input-container">
                        <input type="text" className="custom-input" placeholder={cartSearchPlaceholder}
                               onChange={e => setSearchedValue(e.target.value)}/>
                        <Select data={params.type === "orders" ? orderStatus : offerStatus} placeholder={historyFilterPlaceholder}
                                onChange={e => setChosenStatus(() => params.type === "orders" ? orderStatus.findIndex(el => e === el) : offerStatus.findIndex(el => e === el))}/>
                    </div>}
                    {<div className="content-items content-items__medium" style={{maxWidth: 1300}}>
                        {isFetching && <div style={{padding: 20}}><Loader/></div>}
                        {!isFetching && historyOrders?.data?.length === 0 &&
                            <h3 style={{textAlign: "left", margin: "50px 0px", fontSize: 32}}>{historyEmpty}</h3>}
                        {!isFetching && historyOrders?.data?.length > 0 && historyOrders?.data?.sort((a, b) => sortByDate(a.createdAt, b.createdAt, 'desc'))?.map(item => {
                                return <motion.div
                                    initial={{opacity: 0}}
                                    animate={{opacity: 1}}
                                    transition={{
                                        default: {
                                            duration: .2
                                        }
                                    }
                                    }
                                    key={item.id} className="accordion-container">
                                    <div className="content-accordion__item "
                                         style={{alignItems: 'flex-start', minHeight: 84}}>
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">{cartItemsCompany}</p>
                                            <p className="">{item?.client?.company || "-"}</p>
                                        </div>
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">{params.type === "orders" ? historyOrderNumber : historyOfferNumber}</p>
                                            <p className="">{params.type === "orders" ? item?.orderNumber : item?.offerNumber || "-"}</p>
                                        </div>
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">{cartCreatedAt}</p>
                                            <p className="">{new Date(item?.createdAt)?.toLocaleDateString()}</p>
                                        </div>
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">IFS</p>
                                            <p className="">{item?.internalNumber || "-"}</p>
                                        </div>
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">{cartStatus}</p>
                                            <p className="">{params.type === "orders" ? orderStatus[item?.status] : offerStatus[item?.status]}</p>
                                        </div>
                                        {params.type === 'orders' && <div className='content-accordion__box'>
                                            <p className="content-accordion__title">{historyRealizationDate}</p>
                                            <p className="">{item?.realizationDate ? new Date(item?.realizationDate).toLocaleDateString() : '-'}</p>
                                        </div>}
                                        {params.type === 'offers' && <div className='content-accordion__box'>
                                            <p className="content-accordion__title">{historyValidUntil}</p>
                                            <p className="">{item?.validUntil ? new Date(item?.validUntil).toLocaleDateString() : '-'}</p>
                                        </div>}
                                        {params.type === 'offers' && <div className='content-accordion__box'>
                                            <p className="content-accordion__title">{historyNetValue}</p>
                                            <p className="">{params.type === "offers" ? item?.offerData?.priceData?.netPrice / 100 + " zł" : item?.orderData?.priceData?.netPrice / 100 + " zł" || "-"}</p>
                                        </div>}
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title" >{cartName}</p>
                                            <p className="">{item?.client?.name + " " + item?.client?.surname || "-"}</p>
                                        </div>
                                        {item?.client?.distributor && <div style={{flex: 1, maxWidth: '10%'}}>
                                            <p className="content-accordion__title">{historyDistributor}</p>
                                            <p className=""><a
                                                href="#">{item?.client?.distributor.company}</a>
                                            </p>
                                        </div>}
                                        {params.type === "orders" && <div style={{flex: 1, maxWidth: '10%'}}>
                                            <p className="content-accordion__title">{historyOffer}</p>
                                            <p className="">{item?.offer?.offerNumber || "-"}</p>
                                        </div>}
                                        {params.type === "offers" && <div style={{flex: 1, maxWidth: '10%'}}></div>}
                                        {params.type === "orders" && <div style={{flex: 1, maxWidth: '10%'}}></div>}
                                    </div>
                                    <div className="content-accordion__item--bottom">
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">{historyClientCompany}</p>
                                            <p className="">{params.type === 'orders' ? item?.client?.company || "-" : item?.receiverCompany || "-"}</p>
                                        </div>
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">{historyClientName}</p>
                                            <p className="">{params.type === 'orders' ? item?.client?.name + " " + item?.client?.surname || "-" : item?.receiverName || "-"}</p>
                                        </div>
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">{params.type === 'orders' ? historyOrderInternalNumber : historyOfferInternalNumber}</p>
                                            <p className="">{item?.clientNumber || "-"}</p>
                                        </div>
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">{historyClientAddress}</p>
                                            <p className="">{params.type === 'orders' ? item?.client?.street || "-" : item?.receiverAddress || "-"}</p>
                                        </div>
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">{historyClientZipCode}</p>
                                            <p className="">{params.type === 'orders' ? item?.client?.zipCode || "-" : item?.receiverZipCode || "-"}</p>
                                        </div>
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">{historyClientCity}</p>
                                            <p className="">{params.type === 'orders' ? item?.client?.city || "-" : item?.receiverCity || "-"}</p>
                                        </div>
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">{historyClientCountry}</p>
                                            <p className="">{params.type === 'orders' ? item?.client?.country || "-" : item?.receiverCountry || "-"}</p>
                                        </div>
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">{historyClientPhone}</p>
                                            <p className="">{params.type === 'orders' ? item?.client?.phone || "-" : item?.receiverPhone || "-"}</p>
                                        </div>
                                        <div className='content-accordion__box'>
                                            <p className="content-accordion__title">{historyClientEmail}</p>
                                            <p className="">{params.type === 'orders' ? item?.client?.email || "-" : item?.receiverEmail || "-"}</p>
                                        </div>
                                    </div>
                                    <motion.div
                                        initial={{opacity: 0, y: 5}}
                                        animate={{opacity: 1, y: 0}}
                                        transition={{
                                            delay: .2,
                                            default: {
                                                duration: .1
                                            }
                                        }
                                        }
                                        className="content-accordion-expand">
                                        <div className="basket-container">
                                            <div className="basket-left">
                                                <div className="basket-box">
                                                    {!!item?.offerData?.items?.length && item?.offerData?.items?.map((gate, index) => {
                                                        return (
                                                            <CustomGateDetails gate={gate} item={item} index={index}
                                                                               setChosenGate={setChosenGate}/>
                                                        )
                                                    })}

                                                    {!!item?.orderData?.items?.length && item?.orderData?.items?.map((gate, index) => {
                                                        return (
                                                            <CustomGateDetails gate={gate} item={item} index={index}
                                                                               setChosenGate={setChosenGate}/>
                                                        )
                                                    })}
                                                    {item?.orderData?.accessories?.length > 0 &&
                                                        <CustomAccessories item={item}/>
                                                    }
                                                    {item?.offerData?.accessories?.length > 0 &&
                                                        <CustomAccessories item={item}/>
                                                    }
                                                </div>
                                            </div>
                                            <CustomHistoryItemFooter item={item} gateDetailed={chosenGate}/>
                                        </div>
                                    </motion.div>
                                </motion.div>
                            }
                        )}
                        {debauncedSearchValue.trim().length === 0 && !chosenStatus && totalPages > 1 &&
                            <div className={'pb-24'}>
                                <Pagination total={totalPages} onChange={setPage}/>
                            </div>}
                    </div>}
                    <Link
                        className="main-button"
                        onClick={() => {
                            dispatch(creatorActions.resetCreator())
                            dispatch(summaryBoxActions.resetCurrentStep())
                            if (params.type === "orders") {
                                dispatch(creatorActions.updateCreator({
                                    isOffer: false,
                                    isOrder: true
                                }))
                                dispatch(cartActions.isOfferToggle(false))
                            } else {
                                dispatch(creatorActions.updateCreator({
                                    isOffer: true,
                                    isOrder: false
                                }))
                                dispatch(cartActions.isOfferToggle(true))
                            }
                        }}
                        to="/creator/step/0"
                    >
                        {historyNewProject}
                        <img src={addIcon} alt="background image"/>
                    </Link>
                </div>
            </div>
        </div>
    );

};

export default History;
