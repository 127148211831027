import React, {useEffect, useState} from 'react';
import "./Visualization.scss";
import img from "../../../assets/car.png";
import gateImage from "../../../assets/bez_tloczen_antracyt ciemny.png";
import closeIcon from "../../../assets/icons/close.png";
import zoomInIcon from "../../../assets/icons/zoom_in.png";
import zoomOutIcon from "../../../assets/icons/zoom_out.png";
import rotationIcon from "../../../assets/icons/image.png";
import imageIcon from "../../../assets/icons/3d_rotation.png";
import fullscreenIcon from "../../../assets/icons/fullscreen.png";
import {FullScreen, useFullScreenHandle} from "react-full-screen";
import {motion} from "framer-motion";
import {creatorActions} from "../../../store/slice/gateCreatorSlice";
import {useDispatch, useSelector} from "react-redux";
import {visualizationActions} from "../../../store/slice/visualizationSlice";
import html2canvas from 'html2canvas';
import backgroundImage from "../../../assets/rama2.png";

const Visualization = ({printRef, hiddenVisualization}) => {
        const dispatch = useDispatch();
        const [visualizationStyle, setVisualizationStyle] = useState({scale: 1, rotate: 0})
        const renderImages = useSelector(state => state.visualization);
        const chosenHeight = useSelector(state => state.gateCreator.height);
    const chosenWidth = useSelector(state => state.gateCreator.width);
        const creatorItem = useSelector(state => state.gateCreator);
        const [picture, setPicture] = useState(null);
        const showVisualizationHandler = () => {
            dispatch(creatorActions.showVisualization())
        };

        const handle = useFullScreenHandle();

        const styleHandler = (type) => {
            setVisualizationStyle({
                ...visualizationStyle,
                scale: type === "zoomIn" ? visualizationStyle.scale + .1 : type === "zoomOut" ? visualizationStyle.scale - .1 : null
            })

            if (type === "3d") {
                setVisualizationStyle({
                    ...visualizationStyle,
                    rotate: visualizationStyle.rotate <= 360 ? visualizationStyle.rotate + 12 : 0
                })
            }
        }

        const numbersOfPanels = [...Array(chosenHeight?.panelsAmount).keys()] || [];

        return (
            <motion.div
                initial={{opacity: 0, y: 5}}
                animate={{opacity: 1, y: 0}}
                transition={{
                    default: {
                        duration: .1
                    }
                }
                }
                style={!hiddenVisualization ? {position: "absolute", left: -3000} : null}
                className="content-items content-items__medium">
                <div className="visualization show">
                    <FullScreen handle={handle}>
                        <div className="visualization__container">
                            <div className="visualization-box"
                                 style={{
                                     transform: `scale(${visualizationStyle?.scale})`
                                 }}
                                 ref={printRef}>
                                {renderImages.renders.gate && (
                                    <motion.img
                                        initial={{opacity: 0, y: 5}}
                                        animate={{opacity: 1, y: 0}}
                                        className="visualization-img--background"
                                        src={`${process.env.REACT_APP_API_UPLOADS + renderImages.renders.gate}`}
                                        alt="" style={{
                                        zIndex: 1,
                                        position: 'absolute',
                                        height: '100%',

                                    }}/>
                                )}
                                {renderImages.renders.panel && numbersOfPanels.map(l => (
                                    <motion.img
                                        initial={{opacity: 0, y: 5}}
                                        animate={{opacity: 1, y: 0}}
                                        className="visualization-img"
                                        src={`${process.env.REACT_APP_API_UPLOADS + renderImages.renders.panel}`}
                                        alt="" style={{
                                        zIndex: 2,
                                        height: 540 / chosenHeight?.panelsAmount,

                                    }}/>
                                ))}
                                {renderImages.renders.gateAccessories && renderImages.renders.gateAccessories.map((acc, index) => {
                                    const bottom = (Math.min(...acc.accPanelPos.map(el => el.name)) * (540 / chosenHeight?.panelsAmount)) - (540 / chosenHeight?.panelsAmount);
                                    const left = acc.accHorizonatalPos === 0 ? '50px' : acc.accHorizonatalPos === 1 ? '50%' : '';
                                    const right = acc.accHorizonatalPos === 2 ? '50px' : '';
                                    const maxHeight = 540 / chosenHeight?.panelsAmount * acc.accPanelPos.length;
                                    const minHeight = 25;
                                    const transform = acc.accHorizonatalPos !== 1 ? 'unset' : 'translateX(-50%)';
                                    const renderQuantity = acc?.renderQuantity > 0 ? acc.renderQuantity : undefined;
                                    const multipleByGateWidth = acc?.multipleByGateWidth;
                                    if (renderQuantity && !multipleByGateWidth) {
                                        const images = Array.from({length: renderQuantity}, (_, index) => (
                                            <img
                                                className={`visualization-img`}
                                                src={`${process.env.REACT_APP_API_UPLOADS + acc.accRender}`}
                                                alt="" style={{
                                                zIndex: 3 + index,
                                                maxHeight: maxHeight - (10 * renderQuantity) + 10,
                                                minHeight,
                                                width: acc.accName.toLowerCase().includes('wentylacyjna') ? `${((460 * 100) / chosenWidth.value)}%` : 'unset',
                                                position: acc.renderQuantity === 1 ? 'absolute' : 'unset',
                                                left: acc.renderQuantity === 1 ? left : 'unset',
                                                right: acc.renderQuantity === 1 ? right : 'unset',
                                                transform: acc.renderQuantity === 1 ? transform : 'unset'
                                            }}/>
                                        ))
                                        return (
                                            <motion.div
                                                initial={{opacity: 0, y: 5}}
                                                animate={{opacity: 1, y: 0}}
                                                style={{
                                                    display: "flex",
                                                    alignItems: 'center',
                                                    justifyContent: 'space-around',
                                                    width: '100%',
                                                    height: '100%',
                                                    position: 'absolute',
                                                    bottom: bottom,
                                                    maxHeight,
                                                }}>
                                                {images}
                                            </motion.div>
                                        )
                                    } else if (multipleByGateWidth && !renderQuantity) {
                                        const numbersOfRenders = (creatorItem?.width.value / 1000).toFixed(0);
                                        const images = Array.from({length: numbersOfRenders}, (_, index) => (
                                            <img
                                                className={`visualization-img`}
                                                src={`${process.env.REACT_APP_API_UPLOADS + acc.accRender}`}
                                                alt="" style={{
                                                zIndex: 3 + index,
                                                maxWidth: '100%',
                                                width: 100 / numbersOfRenders + '%',
                                                height: '100%',
                                            }}/>
                                        ))
                                        return (
                                            <motion.div
                                                initial={{opacity: 0, y: 5}}
                                                animate={{opacity: 1, y: 0}}
                                                style={{
                                                    display: "flex",
                                                    alignItems: 'center',
                                                    justifyContent: 'space-around',
                                                    width: '100%',
                                                    height: '100%',
                                                    position: 'absolute',
                                                    bottom: bottom,
                                                    maxHeight,
                                                }}>
                                                {images}
                                            </motion.div>
                                        )
                                    } else {
                                        return (
                                            <motion.img
                                                initial={{opacity: 0, y: 5}}
                                                animate={{opacity: 1, y: 0}}
                                                className={`visualization-img ${acc.accHorizonatalPos === 1 && 'translateX'}`}
                                                src={`${process.env.REACT_APP_API_UPLOADS + acc.accRender}`}
                                                alt="" style={{
                                                zIndex: 3 + index,
                                                position: 'absolute',
                                                left,
                                                right,
                                                bottom: bottom,
                                                width: 'auto',
                                                maxHeight,
                                            }}/>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </FullScreen>
                    <div className="close-btn">
                        <img onClick={showVisualizationHandler} src={closeIcon} alt=""/>
                    </div>
                    <div className="visualization-buttons">
                        <div className="visualization-buttons__item" onClick={() => styleHandler("zoomIn")}><img
                            src={zoomInIcon} alt=""/>
                        </div>
                        <div className="visualization-buttons__item" onClick={() => styleHandler("zoomOut")}><img
                            src={zoomOutIcon} alt=""/>
                        </div>
                        <div className="visualization-buttons__item">
                            <label className="visualization-buttons__item--label" htmlFor="file-background">
                                <img src={rotationIcon} alt=""/>
                            </label>
                            <input
                                onChange={e => {
                                    if (e.target.files[0]) {
                                        setPicture(e.target.files[0]);
                                        const reader = new FileReader();
                                        reader.addEventListener("load", () => {
                                            dispatch(visualizationActions.addBackgroundToVisualization(reader.result))
                                        });
                                        reader.readAsDataURL(e.target.files[0]);
                                    }
                                }}
                                className="visualization-buttons__item--input"
                                type="file" id="file-background"
                            />
                        </div>
                        {renderImages?.background && <div className="visualization-buttons__item"
                                                          onClick={() => {
                                                              dispatch(visualizationActions.resetBackgroundToVisualization())
                                                              setPicture(null)
                                                          }}
                                                          style={{background: "red"}}>
                            <img src={rotationIcon} alt=""/>
                        </div>}
                        <div className="visualization-buttons__item" onClick={() => console.log("test")}><img
                            src={imageIcon}
                            alt=""/></div>
                        <div className="visualization-buttons__item" onClick={handle.enter}><img src={fullscreenIcon}
                                                                                                 alt=""/>
                        </div>
                    </div>
                </div>
            </motion.div>
        );
    }
;

export default Visualization;
