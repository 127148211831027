import React from 'react';
import {Link, useHistory, useParams} from "react-router-dom";
import keyIcon from "../../assets/icons/password.png";
import eyeIcon from "../../assets/icons/eye.png";
import useInput from "../../hooks/useInput";
import '../LoginForm/LoginForm.scss';
import {useState} from "react";

const PasswordReset = () => {
    const params = useParams();
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const {
        value: enteredPassword,
        isTouched: enteredPasswordIsTouched,
        hasError: enteredPasswordHasError,
        enteredValueChangeHandler: enteredPasswordChangeHandler,
        inputBlurHandler: PasswordBlurHandler,
        reset: resetEnteredPassword
    } = useInput(enteredValue => enteredValue?.trim().length > 4);

    const {
        value: enteredRepeatedPassword,
        isTouched: enteredRepeatedPasswordIsTouched,
        hasError: enteredRepeatedPasswordHasError,
        enteredValueChangeHandler: enteredRepeatedPasswordChangeHandler,
        inputBlurHandler: repeatedPasswordBlurHandler,
        reset: resetEnteredRepeatedPassword
    } = useInput(enteredValue => enteredValue?.trim().length > 4);

    const isFormValid = ((!enteredPasswordHasError && enteredPasswordIsTouched) && (!enteredRepeatedPasswordHasError && enteredRepeatedPasswordIsTouched)) && enteredPassword === enteredRepeatedPassword;
    const isPasswordTheSame = enteredPassword !== enteredRepeatedPassword && (enteredPasswordIsTouched && enteredRepeatedPasswordIsTouched);

    const resetPasswordHandler = (e) => {
        e.preventDefault();

        fetch(`${process.env.REACT_APP_API_URL}/api/reset_password/process`, {
            method: "POST",
            body: JSON.stringify({
                token: params.token,
                password: enteredPassword
            }),
            headers: {
                "Content-Type": "application/json"
            }
        }).then(response => {
            if (response.ok) {
                history.push("success")
            }
        })
    }

    return (
        <section className="section-login-form section-reset-password">
            <form onSubmit={resetPasswordHandler} className="form">
                <h2>Resetowanie hasła</h2>
                <div className="form--inp">
                    <label htmlFor="password">Nowe hasło</label>
                    <input
                        onChange={enteredPasswordChangeHandler}
                        type={showPassword? "text" : "password"}
                        id="password"
                        placeholder="Wpisz nowe hasło" required/>
                    <img
                        onClick={()=>setShowPassword(!showPassword)}
                        className="custom-icons"
                        src={eyeIcon} alt="login icon"
                    />
                </div>
                <div className="form--inp">
                    <label htmlFor="new_password">Powtórz nowe hasło</label>
                    <input
                        onChange={enteredRepeatedPasswordChangeHandler}
                        type={showNewPassword? "text" : "password"}
                        id="new_password"
                        placeholder="Powtórz nowe hasło"
                        required/>
                    <img
                        onClick={()=>setShowNewPassword(!showNewPassword)}
                        className="custom-icons"
                        src={eyeIcon} alt="login icon"
                    />
                </div>
                {isPasswordTheSame && <p style={{color: "red", fontSize: 14, marginBottom: 15, fontWeight: 600}}>Hasła muszą być takie same!</p>}
                <button
                    disabled={!isFormValid}
                    type="submit"
                    className="btn btn-primary">
                    Resetuj hasło <img className="custom-icons" src={keyIcon} alt="login icon"/>
                </button>
                <div className="form__underline"></div>

                <p className="mb-0 body-m text-center"><Link to="/login">Wróć do logowania</Link></p>
            </form>
        </section>
    );
};

export default PasswordReset;