import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isOpen: false,
    numberOfSteps: 4,
    currentStep: 0,
}

const summaryBoxSlice = createSlice({
    name: "summaryBox",
    initialState,
    reducers: {
        toggleSummaryBoxDescription(state) {
            state.isOpen = !state.isOpen
        },
        updateNumberOfSteps(state, action) {
            state.numberOfSteps = action.payload
        },
        resetCurrentStep(state) {
            state.currentStep = 0
        },
        updateCurrentStep(state, action) {
            state.currentStep = action.payload
        },
        nextCurrentStep(state) {
            state.currentStep = state.currentStep + 1
        },
        previousCurrentStep(state) {
            state.currentStep = state.currentStep - 1
        }
    }
})

export const summaryBoxActions = summaryBoxSlice.actions;

export default summaryBoxSlice.reducer;