import React from 'react';
import closeIcon from "../assets/icons/close.png";
import {motion} from "framer-motion";
import {useDispatch} from "react-redux";
import {creatorActions} from "../store/slice/gateCreatorSlice";

const SummaryDescription = ({chosenAccessory}) => {
    const dispatch = useDispatch();
    const closeSummaryBoxDescription = () => {
        dispatch(creatorActions.hideDescription())
    }
    return (
        <motion.div
            initial={{opacity: 0, y: 5}}
            animate={{opacity: 1, y: 0}}
            transition={{
                default: {
                    duration: .1
                }
            }
            }
            className="summary-description"
            style={{flexGrow: 0}}>
            <div className="summary-box">
                <div className="icon-close" onClick={closeSummaryBoxDescription}>
                    <img src={closeIcon} alt="background image"/>
                </div>
                <h3 className="summary-description__title">{chosenAccessory?.name}</h3>
                {chosenAccessory?.image?.contentUrl &&
                    <img src={`${process.env.REACT_APP_API_UPLOADS + chosenAccessory?.image?.contentUrl}`} alt=""
                         style={{width: 100, height: 100}}/>}

                <p className="summary-description__txt">{chosenAccessory?.description}</p>
                {chosenAccessory.youtubeUrl && <div className="social-icon">
                    <iframe src={chosenAccessory.youtubeUrl + "?autoplay=1"} frameborder="0" allow='autoplay'
                            allowFullScreen={true}></iframe>
                </div>}
            </div>
        </motion.div>
    );
};

export default SummaryDescription;
