import {configureStore} from "@reduxjs/toolkit";
import clientSlice from "./slice/clientSlice";
import componentSlice from "./slice/componentSlice";
import creatorSlice from "./slice/gateCreatorSlice";
import summarySlice from "./slice/summarySlice";
import cartSlice from "./slice/cartSlice";
import orderSlice from "./slice/orderSlice";
import uiSlice from "./slice/uiSlice";
import authSlice from "./slice/authSlice";
import userSlice from "./slice/userSlice";
import contentSlice from "./slice/contentSlice";
import visualizationSlice from "./slice/visualizationSlice";
import gateCreatorSlice from "./slice/gateCreatorSlice";


const store = configureStore({
    reducer: {
        clients: clientSlice,
        components: componentSlice,
        gateCreator: gateCreatorSlice,
        summaryBox: summarySlice,
        cart: cartSlice,
        order: orderSlice,
        ui: uiSlice,
        auth: authSlice,
        user: userSlice,
        content: contentSlice,
        visualization: visualizationSlice
    }
});


export default store;
